@import '../../shared/styles/shared';

.keyPerformanceIndicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding: 0 1rem;
  border-left: 1px solid $background;
  flex-basis: 25%;
  text-align: center;
  &:first-child {
    border-left: none;
  }
  .value {
    font-size: $fontBig;
    color: $purple;
    font-weight: 400;
    .trailingText {
      font-size: $fontNorm;
      color: $purple;
    }
  }
  .label {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: $purple;
    font-weight: 400;
  }
}

@media (max-width: 750px) {
  .keyPerformanceIndicator {
    flex-direction: row-reverse;
    border: none;
    width: 100%;
    justify-content: flex-end;
    .value {
      margin-left: 5px;
      font-size: 20px;
      color: $purple;
      font-weight: 200;
      .trailingText {
        font-size: 20px;
        color: $purple;
      }
    }
    .label {
      font-size: 20px;
      margin: 0px;
    }

    .label::after {
      content: ': ';
    }
  }
}
