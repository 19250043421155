@import '../../shared/styles/shared';

.h2 {
  font-size: 1.5rem;
  margin-bottom: 0rem;
}

.inputRow {
  display: flex;

  > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.inputGroup {
  margin-bottom: 1.5rem;
}
.resetPanelWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.resetPanel {
  min-width: 320px;
  max-width: 520px;
  flex-basis: auto; /* default value */
  flex-grow: 1;
  padding: 20px;
}
.resetPage {
  position: relative;
  z-index: 1;
  width: 100%;

  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;

  background-color: $background;
}

.logo {
  svg {
    height: 75px;
    display: block;
    margin: 0 auto 2rem;
  }
}
.small {
  font-size: $fontSmall;
}
.auxActions {
  display: flex;
  margin-top: 1rem;
  > div {
    flex-grow: 1;
    text-align: center;
  }
}
.zip {
  flex-basis: 5rem;
}
.alert {
  animation: pulse 1.5s infinite alternate;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  h2 {
    background-color: $warning;
    font-weight: 300;
    padding: 0.5rem;
    margin-bottom: 0;
    font-size: $fontSmall;
  }
  p {
    margin-top: 0;
    padding: 0.5rem;
  }

  @keyframes pulse {
    from {
      box-shadow: 0px 0px 10px rgba($warning, 0.2);
    }
    to {
      box-shadow: 0px 0px 40px rgba($warning, 1);
    }
  }
}
