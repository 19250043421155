@import '../../shared/styles/shared';

.keyPerformanceIndicators {
  display: flex;
  margin: 1.5rem 0;
  background-color: #fff;
  height: 8rem;
  align-items: center;
  border-radius: $radiusNorm;
}

@media (max-width: 750px) {
  .keyPerformanceIndicators {
    display: flex;
    margin: 1.5rem 0;
    background-color: #fff;
    height: 100%;
    align-items: center;
    border-radius: $radiusNorm;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 10px 0px;
  }
}
