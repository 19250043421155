@import '../../../shared/styles/shared';
:export {
  lineColor: $grey;
  lineColorBL: $grey;
  lineColorMD: $midgrey;
  ISQhigh: $ISQhigh;
  ISQmedium: $ISQmedium;
  ISQlow: $ISQlow;
  ISQneutral: $ISQneutral;
  ISQneutralMuted: $ISQneutralMuted;
  gridLine: $subtlegrey;
  purple: $purple;
  vividpurple: $vividpurple;
  noColor: $grey;
  background: $background;
}

.overviewWrapper {
  display: flex;
  > * {
    width: 50%;
  }
}
.dataRow {
  display: flex;
  margin-bottom: 2rem;
}

.chartWrapper {
  position: relative;
  background-color: $background;
  border-radius: $radiusNorm;
}
.toolTip {
  color: $grey;
  &.projection {
    color: $vividpurple;
  }
  .label {
    font-size: $fontMidi;
    font-weight: 300;
  }
  .data {
    font-size: $fontSmall;
  }
  .manual {
    font-size: $fontSmall;
  }
}

.chartOptions {
  z-index: 1;
  margin-bottom: -10px;
}
