@import '../../shared/styles/shared';

.dentalChartContainer {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.patientDataContainer {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.instrumentStatus {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.buttons {
  width: 100%;
  text-align: center;
}

.actionGroup {
  margin-top: 3rem;
}

.h1 {
  display: inline-block;
  margin-bottom: 20px;
}

.measurement {
  font-size: 15rem;
  text-align: center;
}
.dir {
  height: 50px;
  font-size: 8rem;
  text-align: center;
}

.riskButton {
  border: 1px solid #812973;
  min-height: 23px;
  padding: 0.2rem 0.8rem;
  min-width: auto;
  margin-left: 20px !important;
}

.riskFactors {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  div {
    display: flex;
  }
  .riskFactor {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 13px;
    padding: 5px 10px;
    margin: 5px;
  }
}

.fullPage {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 71px);
}

.selectToothText {
  color: $purple;
  margin: 0 auto;
  padding: 2rem;
}

.mobileTitle {
  display: none;
}

.patientSectionInner {
  padding: 0px 0px 15px 0px !important;
}

@media (max-width: 750px) {
  .dentalChartContainer {
    width: calc(100vw - 25px);
    margin: 0 auto;
    overflow-x: scroll;
    border: solid purple 3px;
    border-radius: 5px;
    box-sizing: border-box;
  }

  .mobileTitle {
    margin: 15px 0px 0px 0px;
    text-align: center;
    display: block;
  }

  .fullPage {
    height: calc(100vh - 50px);
  }
}
