@import '../../shared/styles/shared';
.h2 {
  font-size: 1.5rem;
}
.retrievePage {
  position: relative;
  z-index: 1;
  width: 100%;
  background-color: $background;
  overflow: auto;
}
.panelWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.panel {
  //min-width: 320px;
  //max-width: 320px;
  flex-basis: auto; /* default value */
  //flex-grow: 1;
  padding: 20px;
}

.logo {
  svg {
    height: 75px;
    display: block;
    margin: 0 auto 2rem;
  }
}
.small {
  font-size: $fontSmall;
}
.auxActions {
  display: flex;
  margin-top: 1rem;
  > div {
    flex-grow: 1;
    text-align: center;
  }
}

.cancelButton {
  margin-top: 10px !important;
  border: #808080 solid 1px;
}
