@import '../../../shared/styles/shared';
.collapsible {
  margin: 10px 0 20px 0;
}

.title {
  background-color: #fff;
  position: relative;
  border-radius: $radiusNorm;
  color: $purple;
  font-size: $fontMidi;
  padding: 0.8rem 2.5rem 1rem 1rem;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: -10px;
  cursor: pointer;

  &:hover {
    outline: none;
    border: none;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);
  }

  transition: all 0.3s ease;
  &:after {
    content: 'arrow_down';
    font-family: 'Osstell Icons';
    position: absolute;
    font-size: $fontMidi;
    top: 0;
    right: 0rem;
    border-radius: 0 $radiusNorm $radiusNorm 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    pointer-events: none;
  }
}

.content {
  color: $purple;
  background-color: #fff;
  border-radius: $radiusNorm;
  cursor: default;
  transition: all 0.25s ease;
  border-bottom: 1px solid $subtlegrey;
  padding: 1.5rem;
}

@media (max-width: 700px) {
  .title {
    font-size: 14px !important;
  }
}
