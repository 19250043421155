@import '../../shared/styles/shared';

.chartWrapper {
  margin-top: 1rem;
}
.legend {
  font-size: 12px;
  font-weight: 400;
}
.legendBottom {
  text-align: right;
}
.legendTop {
  margin-bottom: 0.5rem;
}
.footer {
  margin-top: 0.5rem;
  font-weight: 300;
  font-size: 0.75rem;
  min-height: 15px;
}
.chartPanel {
  align-self: stretch;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.chartHeader {
  h2 {
    font-size: 0.875rem;
  }
}
.noData {
  font-weight: 300;
  height: 286px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tooltip {
  background-color: #fff;
  padding: 5px;
  border: solid 1px $grey;
  border-radius: 5px;
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    :first-child {
      margin-right: 5px;
    }
  }
}
