@import '../../../shared/styles/shared';

.spinnerBackground {
  background-color: $vividpurple !important;
}

.headerRow {
  margin-bottom: 1.5rem;
}

.marginTop {
  margin-top: 1rem;
}
.dropDownToggle {
  width: 2rem;
  display: block;
  font-size: 1.5rem;
  text-align: center;
  float: right;
}
