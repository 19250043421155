@import '../../../shared/styles/shared';
$paddingHorizontal: 1.25rem;
.table {
  width: calc(100%);
  font-weight: 400;
  border-collapse: collapse;
  // margin: 0 -1rem -0.75rem -1rem;
  border-collapse: separate;
  border-spacing: 0 3px;
}

.tableHead {
  text-align: left;
}

.th {
  padding: 1rem $paddingHorizontal 0.25rem;
  font-weight: 700;
  // font-size: 0.8rem;
}

.trHead {
  border-bottom: 3px solid $background;
}

.trBody {
  border-bottom: 3px solid $background;
}

.trBody:last-child {
  border-bottom: none;
}

.tr {
  // background-color: #fff;
  white-space: nowrap;
  &:hover {
    td {
      // background-color: $lightpurple;
    }
  }
}
.contentWidth {
  width: 1%;
  white-space: nowrap;
}
.td {
  padding: 0.75rem $paddingHorizontal;
  background-color: #fff;
  vertical-align: middle;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.separator {
  text-align: center;
  border-left: 2px solid $background;
  font-size: 1.5rem;
  width: 1%;
  white-space: nowrap;
}
.separator {
  color: $purple;
  cursor: pointer;
}
.button {
  cursor: pointer;
  padding: 0;
  &:hover {
    background: $shadedwhite;
  }
}
.td a {
  color: $purple;
  text-decoration: none;
}
