@import '../../shared/styles/shared';
:export {
  grey: $grey;
  lineColor: $grey;
  lineColorBL: $grey;
  lineColorMD: $midgrey;
  ISQhigh: $ISQhigh;
  ISQmedium: $ISQmedium;
  ISQlow: $ISQlow;
  ISQneutral: $ISQneutral;
  purple: $purple;
  vividpurple: $vividpurple;
  background: $background;
}

.tabHeading {
  text-align: center;
  margin-top: 0px;
}
.implantPanel {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tabWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.fillerDiv {
  height: 100%;
  background-color: white;
  z-index: 1;
}

.tabContent {
  background: white;
  display: flex;

  > div {
    box-shadow: 0 -2px 8px rgba(52, 51, 89, 0.16);
    flex-grow: 1;
    padding: 1.5rem 4.5rem;
  }
}

.columnWrapper {
  display: flex;
  height: 100%;
}
.overviewWrapper {
  box-sizing: border-box;
  display: flex;
  margin: 0 auto;
  max-width: 980px;
  > * {
    flex-basis: 100%;
  }
  padding-bottom: 60px;
}
.chartWrapper {
  & > div {
    display: flex;
    flex-direction: column;
    @include for-tablet-landscape-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    > h3 {
      flex-grow: 1;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }
  }
  .switchWrapper {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    align-self: flex-end;
    margin-bottom: 0.5rem;
    & > span {
      margin-right: 0.5rem;
      font-size: $fontNorm;
      font-weight: 400;
    }
  }
}

.dataCol {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-top: 0.5rem;
  &:not(:first-child) {
    border-left: 1px solid $background;
    padding-left: 3rem;
    &.implantCol {
      padding-left: 4.5rem;
    }
  }
  &:not(:last-child) {
    padding-right: 3rem;
    &.implantCol {
      padding-right: 4.5rem;
    }
  }
  &.actionCol {
    width: 170px;
    max-width: 170px;
    & > * {
      margin-bottom: 0.5rem;
      margin-right: 0;
    }
  }
  &.historyCol {
    max-width: 16rem;
    button {
      width: 100%;
      max-width: 16rem;
    }
    & > * {
      margin-bottom: 0.5rem;
      margin-right: 0;
    }
    .currentImplant {
      background-color: #fff;
    }
    .historyImplant {
      border: 1px solid $background;
    }
    .activeImplant {
      border: 1px solid $purple;
    }
  }
  & > :last-child {
    margin-bottom: 0;
  }
  & > h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
.historyButtonText {
  color: $grey;
  font-weight: 500;
  flex: 1;
  text-align: left;
}
.historyButtonDate {
  color: $grey;
}

.historySection {
  border-bottom: 1px solid $background;
  margin-bottom: 1rem;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
  & > h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
.dataRow {
  display: flex;
  margin-bottom: 1.125rem;
  &.spread {
    justify-content: space-between;
    & > :last-child {
      text-align: right;
    }
    & > :first-child {
      text-align: left;
    }
  }
}
.dataItem {
  flex-basis: initial;
  white-space: pre-wrap;
  flex-grow: 1;
  &.value {
    color: red;
  }
  &.equal {
    flex-basis: 50%;
  }
}
.dataItemSmall {
  flex-basis: 45%;
}

.dataItemBig {
  flex-basis: 100%;
}

.buttonFixedWidth {
  width: 170px;
}

.newTreatment {
  margin-top: 3.25rem;
  background: white;
  box-shadow: 0 -2px 8px rgba(52, 51, 89, 0.16);
  flex-grow: 1;
  & > div {
    // height: 100%;
    padding: 1.5rem 4.5rem;
    .columnWrapper {
      margin: 0 auto;
      max-width: 980px;
      justify-content: flex-end;
    }
  }
  // -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  //      -moz-animation: fadein 1s; /* Firefox < 16 */
  //       -ms-animation: fadein 1s; /* Internet Explorer */
  //        -o-animation: fadein 1s; /* Opera < 12.1 */
  //           animation: fadein 1s;
}

.abutmentNote {
  font-size: $fontSmall;
  padding: 0.5rem;
  font-style: italic;
}

.isqInfoIcon {
  margin-top: 0.5rem;
}

.overviewContainer {
  position: relative;
  box-sizing: border-box;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 16px;
  background-color: unset;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 40px;
    color: $purple;
    font-size: 2rem;
  }
}

.headerContainer {
  display: flex;
  .editButton {
    margin-right: 15px;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $background;
    border-radius: $radiusNorm;
    border: none;
    color: $purple;
    cursor: pointer;
    .editIcon {
      font-size: 1.625rem;
      padding-top: 3px;
    }
    &:focus {
      outline: none;
    }
  }
}

@media (max-width: 890px) {
  .overviewWrapper {
    flex-direction: column;
    .dataCol {
      width: 100%;
      margin-bottom: 20px;
      padding: 0px 0px 20px 0px !important;
      border-left: unset;
      border-bottom: 1px solid $background;
    }
    .dataCol.historyCol {
      max-width: unset;
      width: 100%;
    }
    .dataCol:last-child {
      border: none !important;
      padding-bottom: 0px !important;
    }
  }

  .overviewWrapper.reverseColumn {
    flex-direction: column-reverse !important;
    .dataCol {
      padding: 20px 0px 0px 0px !important;
      border-top: 1px solid $background;
      border-bottom: 0px;
    }
    .dataCol:last-child {
      padding-top: 0px !important;
    }
  }

  .overviewContainer {
    padding: 15px 15px !important;
  }
}

@media (max-width: 450px) {
  .actionCol {
    max-width: none !important;
    width: 100% !important;
  }
}
