@import '../../shared/styles/shared';

.tile {
  border-radius: 10px;
  cursor: pointer;
  width: 250px;
  height: 148px;
  background-color: #fff;
  padding: 1rem;
  box-sizing: border-box;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.499rem;
  margin-right: 0.499rem;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
}
.tile:hover {
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.08);
}
.icon {
  margin-top: 10px;
  text-align: center;
  font-size: 3rem;
  color: $purple;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $purple;
  font-size: 0.8rem;
  background-color: #fff;
}

@media (max-width: 700px) {
  .tile {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
