@import '../../../shared/styles/shared';
.tabList {
  padding: 0;
  display: flex;
  background: white;
  margin: 0 auto;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 0.75rem;
  li {
    padding: 0.75rem 2rem;
    color: $purple;
    outline: 0;
    list-style: none;
    font-size: $fontSmall;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    letter-spacing: 1px;
    transition: 0.5s ease;
    &.active {
      color: white;
      background-color: $purple;
      box-shadow: 0 4px 8px rgba(115, 37, 102, 0.24);
    }
    &:hover {
    }
  }
}
.implantTabPanelSelected {
  flex: 1;
}

.textTabSpan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  font-weight: 600;
  .iconOnTextTab {
    font-size: 26px;
    margin-left: 5px;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}

.iconTabs {
  min-width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    text-align: center;
    font-size: 30px;
  }
}

@media (max-width: 700px) {
  .tabList {
    li {
      padding: 0.75rem 1rem;
    }
  }
}
