@import '../../shared/styles/shared';

.tile {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 15px 0px 0px 0px;
}

.icon {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0px;
  left: 0px;
  border-radius: 5px 0px;
  background-color: $purple;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
  font-size: 1rem;
}
.title {
  text-align: center;
  color: $purple;
  font-size: 1rem;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .icon {
    height: 100%;
    border-radius: 5px 0px 0px 5px;
    vertical-align: center;
    line-height: 50px;
  }
}
