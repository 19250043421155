@import '../../../shared/styles/shared';
.dropdown {
  transition: all 0.25s ease;
  position: relative;
  // z-index:10000;
  box-shadow: 1px 1px 25px rgba(0, 0, 0, 0);
  &.active {
    // background-color:#fff;
    .options {
      box-shadow: $toggledShadow;
      visibility: visible;
      opacity: 1;
    }
  }
  &:hover {
    // color:$vividpurple;
  }
  &:focus {
    outline: none;
  }
  .dropdownToggle {
    position: relative;
    z-index: 1;
    cursor: pointer;
    outline: none;
    height: 100%;
    :focus {
      outline: none;
    }

    &.active {
      // background-color:#fff;
      z-index: 2;
    }
  }
  .options {
    box-shadow: 1px 1px 25px rgba(0, 0, 0, 0);
    position: absolute;
    transition: all 0.25s ease;
    // font-size: $fontSmall;
    font-weight: 300;
    color: $grey;
    visibility: visible;
    background: #fff;
    z-index: 10000;
    overflow: hidden;
    margin-top: 0rem;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-flow: column;
    border-radius: $radiusNorm;
    &:focus {
      outline: none;
    }
    &.alignLeft {
      left: 0;
    }
    &.alignRight {
      right: 0;
    }
    &.fullWidth {
      width: 100%;
    }
    &.active {
      pointer-events: inherit;
      .option {
        opacity: 1;
        cursor: pointer;
      }
    }
    &.searchSelect {
      margin-top: -0.5rem;
    }
    &.searchBox {
      top: 0;
      .inner {
        flex: 1;
        max-height: 350px;
        overflow-y: scroll;
      }
    }

    .option {
      opacity: 0;
      cursor: default;
      transition: all 0.25s ease;
      border-bottom: 1px solid $subtlegrey;
      padding: 1rem 1.5rem;
      line-height: 1;
      white-space: nowrap;
      box-sizing: border-box;
      font-size: $fontNorm;
      text-align: left;
      &:last-child {
        border-bottom: transparent;
      }
      &:first-child {
        border-top: 1px solid $subtlegrey;
      }
      &:hover {
        background: $shadedwhite;
        color: $vividpurple;
        // padding-left:1.5rem;
      }
    }
  }
}
.footer {
  position: sticky;
  bottom: 0px;
  padding: 1rem 2rem;
  font-size: $fontMidi;
  color: $purple;
  text-align: center;
  background-color: #fff;
  box-shadow: $toggledShadow;
  cursor: pointer;
  pointer-events: visible;
}
.disabledOption {
  opacity: 0.7;
  transition: all 0.25s ease;
  border-bottom: 1px solid $subtlegrey;
  padding: 1rem 2rem;
  line-height: 1;
  white-space: nowrap;
  box-sizing: border-box;
}

.notificationPadding {
  padding-left: 0.75rem !important;
}

.notificationCounter {
  background-color: #bd004d;
  color: #fff;
  border-radius: 3px;
  padding: 1px 3px;
  font: 10px Verdana;
  margin-right: 0.5rem;
}
