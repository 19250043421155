@import '../../shared/styles/shared';

.h1 {
  display: inline-block;
  margin-bottom: 0;
}

.buttonGroup {
  position: relative;
  right: 0px;
  margin-top: 0.5rem;
  padding: 0 1rem;
}

.spinnerBackground {
  background-color: $vividpurple !important;
}

.searchFlexContainer {
  display: flex;
  justify-content: space-around;
  .dropdownButton {
    min-height: 37px !important;
    border-radius: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (max-width: 700px) {
  .searchFlexContainer {
    margin-top: 30px;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: stretch;
  }

  .header {
    text-align: center;
  }

  .dropdown {
    margin-top: 7px;
  }

  .dropdownButton {
    width: 100%;
  }
}
