@import '../../shared/styles/shared';

@font-face {
  font-family: 'Osstell Icons Custom';
  font-weight: 200;
  font-style: normal;
  src: url('./local-assets/Osstell.eot'); /* IE9 Compat Modes */
  src: url('./local-assets/Osstell.eot?#iefix') format('embedded-opentype'),
    url('./local-assets/OsstellRegular.woff2') format('woff2'),
    url('./local-assets/OsstellRegular.woff') format('woff'),
    url('./local-assets/Osstell.otf') format('opentype');
}

@font-face {
  font-family: 'Osstell Icons Custom Light';
  font-weight: 200;
  font-style: normal;
  src: url('./local-assets/Osstell.eot'); /* IE9 Compat Modes */
  src: url('./local-assets/Osstell.eot?#iefix') format('embedded-opentype'),
    url('./local-assets/OsstellLight.woff2') format('woff2'),
    url('./local-assets/OsstellLight.woff') format('woff'),
    url('./local-assets/Osstell-Light.otf') format('opentype');
}

.smartPegToolPanelWrapper {
  justify-content: center;
  display: flex;
  height: 100%;
}
.smartPegToolPanel {
  min-width: 280px;
  max-width: 520px;
  flex-basis: auto; /* default value */
  flex-grow: 1;
  margin: 8px;
}
.loading {
  text-align: center;
}
.fullHeight {
  height: 100%;
  overflow-y: auto;
}

.landingPageRoot {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.maxWidthWrapper {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.h1 {
  font-size: 1.4rem;
  color: #000;
  font-weight: 500;
  justify-content: center;
  margin: 1.5rem;
}

.resultListWrapper {
  list-style-type: none;
  padding: 0;
  margin: 0 10px;
}

.fontStyling {
  color: $purple;
  font-family: 'Osstell Icons Custom';

  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon {
  @extend .fontStyling;
}
.itemIcon {
  font-size: 2.75rem;
  @extend .fontStyling;
}

.backIcon {
  float: left;
  font-size: 2rem;
  user-select: none;
  margin-left: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  @extend .fontStyling;
}

.forwardIcon {
  float: left;
  font-size: 1.75rem;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
  @extend .fontStyling;
}

.itemIconLabel {
  font-size: 0.9rem;
  font-weight: 300;
}

.iconButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  @extend .shadow;
  // background-color: $shadedwhite;
  padding: 0.75rem;
  width: 6rem;
  height: 6rem;
  justify-content: space-between;
  &:hover {
    background-color: $shadedwhite;
    cursor: pointer;
  }
}

.iconButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchBox {
  height: 2.5rem;
  width: 100%;
  padding-left: 0.7rem;
  // background-color: $shadedwhite;
  // border: 1px solid $subtlegrey;
  border: none;
  @extend .shadow;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
  caret-color: $purple;
  box-sizing: border-box;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $purple;
  }
}

.searchResultsWrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.shadow {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
}
.searchResultsLink {
  padding: 16px;
  &:hover {
    background-color: $shadedwhite;
    cursor: pointer;
  }
}
.searchResultsFlat {
  border-top: 1px solid #ddd;
}
.searchResultsMiddleWrapper {
  flex-grow: 1;
}
.searchResultsMiddleWrapperArrow {
  align-self: center;
  padding-left: 1rem;
}

.searchResults {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  font-size: 0.8rem;
}

.searchResultHeader {
  font-weight: 500;
  font-size: 0.75rem;
  margin: 0 0 10px 0;
}

.searchResultText {
  font-weight: 500;
}
.searchResultLabel {
  font-weight: 300;
  color: $midgrey;
}

.brandNameItem {
  margin: 5px 0;
  font-weight: 300;
  &:hover {
    background-color: $shadedwhite;
    cursor: pointer;
  }
}

.modelSearchResults {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.modelSearchResultsItem {
  width: 9rem;
  height: 9rem;
  margin: 0.3rem;
  box-sizing: border-box;
  padding: 20px;

  font-size: 1rem;
  font-weight: 300;
  position: relative;
  @extend .shadow;
  &:hover {
    background-color: $shadedwhite;
    cursor: pointer;
  }
}

.modelSearchItemText {
  font-weight: 300;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: 1rem;
}

.smartPegListWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
}

.smartPegListResultItem {
  width: 9rem;
  height: 9rem;
  margin: 0.3rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding: 20px;
  @extend .shadow;
  font-size: 1rem;
  font-weight: 300;
  position: relative;
  &:hover {
    background-color: $shadedwhite;
    cursor: pointer;
  }
}

.smartPegListResultItemContent {
  font-weight: 300;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.singelSmartPegWrapper {
  text-align: center;
  margin-bottom: 2rem;
}

.divider {
  margin: 2.5rem 0 1.5rem 0;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  &:before {
    margin-right: 0.75rem;
    margin-left: 0;
    content: '';
    flex: 1;
    border-bottom: 1px solid $lightgrey;
  }

  &:after {
    margin-right: 0;
    margin-left: 0.75rem;
    content: '';
    flex: 1;
    border-bottom: 1px solid $lightgrey;
  }
}

.smartPegImgSmall {
  width: 1.3rem;
}

.smartPegImgBig {
  width: 4rem;
  margin: 1rem 0;
}

.addToCartQuantity {
  height: 2.5rem;
  width: 5rem;
  background-color: $shadedwhite;
  // border: 1px solid $subtlegrey;
  border: none;
  @extend .shadow;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
  caret-color: $purple;
  box-sizing: border-box;
  text-align: center;
  padding-left: 1rem;
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $purple;
  }
}

.addToCartButton {
  height: 2.5rem;
  background: $purple;
  color: white;
  outline: none;
  border: none;
  font-weight: 300;
  font-family: inherit;
  margin-left: 1rem;
  width: 15rem;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    border: none;
  }
}

.currencyText {
  float: right;
}

.inlineBlock {
  display: inline-block;
}

.quantityLabel {
  display: block;
  font-size: 0.9rem;
  padding-bottom: 0.3rem;
}

.compatibleWrapper {
  text-align: left;
  font-weight: 300;
  padding: 10px;
  @extend .shadow;
  margin-bottom: 20px;
}
.collapsibleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-weight: 500;
  cursor: pointer;
  i {
    font-size: 1.5rem;
    color: #000;
  }
}
.collapsible {
  margin: 10px 0px;
  cursor: pointer;
  display: block !important;
  width: 100%;
  font-size: 1rem;
}
.collapsibleOpen {
}
.collapsibleInner {
  margin: 10px 16px;
  .Collapsible {
  }
}

.trigger {
  &:before {
    content: 'arrow_right';
    margin-right: 10px;
    @extend .fontStyling;
    color: #95989a;
  }
}
.triggerOpen {
  &:before {
    content: 'arrow_down';
    margin-right: 10px;
    @extend .fontStyling;
    color: #95989a;
  }
}
.separatorHeader {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  margin-top: 16px;
}

.downloadsmartpegguide {
  width: 100%;
}
