body {
  color: black;
}

h1 {
  font-size: 3rem;
  font-weight: 100;
  color: $purple;
  margin-bottom: 1rem;
  display: flex;
}
a {
  text-decoration: none;
  color: $vividpurple;
  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: 750px) {
  body {
    p,
    h2,
    h3 {
      color: black;
    }
  }
}
