@import '../../shared/styles/shared';

.errorPanel {
  margin: 2rem auto;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  max-width: 650px;
  border: 1px solid $destructive;
  border-radius: 0.5rem;
  background-color: $shadedwhite;

  .errorTitle {
    margin: 0;
    font-size: 1rem;
    color: $destructive;
  }

  .errorSubtitle {
    margin: 0;
    color: $destructive;
  }
}
