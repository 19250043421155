@import '../../../shared/styles/shared';

.spinnerBackground {
  background-color: $vividpurple !important;
}

.headerRow {
  margin-bottom: 1.5rem;
}

.marginTop {
  margin-top: 1rem;
}

.marginRight {
  margin-right: 5px;
}

.findButton {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.searchTypeDropdown {
  width: 250px;
}
