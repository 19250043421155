@import '../../shared/styles/shared';

.smartPegSelectionList {
  list-style: none;
  padding: 0;
}

.infoText {
  font-style: italic;
}
