@import '../../shared/styles/shared';
:export {
  purple: $purple;
}
.h2 {
  font-size: 1.5rem;
}
.loginPanelWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}
.loginPanel {
  min-width: 320px;
  max-width: 320px;
  flex-basis: auto; /* default value */
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  @include for-tablet-portrait-up {
    box-sizing: initial;
  }
}
.loginPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $background 0% 0% no-repeat padding-box;
  overflow: auto;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}
.loginForm {
  min-width: 50%;
  background: #f4f5fc 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 50px #8133a236;
  z-index: 10;
}
.info {
  flex-grow: 1;
  color: $greyText;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
  font-size: $fontNorm;
  position: relative;
  .videoWrapper {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;
    height: 100%;
    width: 100%;
    .videoBg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100vh;
      min-width: 100%;
      min-height: 100vw;
    }
    .videoShade {
      position: absolute;
      background-color: rgba(255, 255, 255, 0.3);
      z-index: 2;
      width: 100%;
      height: 100%;
    }
  }
  .textOverlay {
    width: 80%;
    z-index: 5;
    text-align: center;
    padding: 1.5rem;
  }
  .infoHeader {
    // font-weight: 700;
    margin: 0;
  }
  .infoText {
    margin: 0;
    font-weight: 300;
  }
  .link {
    margin-top: 1.5rem;
  }
}
.logo {
  svg {
    height: 75px;
    display: block;
    margin: 0 auto 2rem;
  }
}
.small {
  font-size: $fontSmall;
}
.auxActions {
  display: flex;
  flex-direction: column;
  font-size: $fontMidi;
  font-weight: 300;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @include for-tablet-landscape-up {
    margin-top: 3rem;
  }

  > div {
    flex-grow: 1;
    text-align: center;
    color: #333;
    margin-top: 1rem;
  }
}

.siteNote {
  text-align: center;
  color: $purple;
}

.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  & > span {
    margin-right: 0.5rem;
    font-size: $fontNorm;
    font-weight: 200;
  }
}

.termsOfServiceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  max-width: 500px;
}

@media (max-width: 750px) {
  .info {
    background: #f4f5fc 0% 0% no-repeat padding-box;
  }
  .videoWrapper {
    display: none;
  }
}
