@import '../../shared/styles/shared';

.step {
  display: flex;
  align-items: center;

  .stepIcon {
    height: 44px;
    width: 44px;
    color: $vividpurple;
    border: 1px solid $vividpurple;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 30px 5px 10px;
    i {
      font-size: 1.5rem;
    }
  }
}

.divider {
  border-left: 1px solid $vividpurple;
  height: 15px;
  margin-left: 32px;
}

.h2 {
  font-size: 1.5rem;
  margin-bottom: 0rem;
}
.comment {
  font-size: $fontSmall;
  display: block;
}
.inputRow {
  display: flex;

  > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.inputGroup {
  margin-bottom: 1.5rem;
}
.registrationPanelWrapper {
  justify-content: center;
  // align-items: center;
  // margin-top:14rem;
  display: flex;
  height: 100%;
}
.registrationPanel {
  min-width: 320px;
  max-width: 520px;
  flex-basis: auto; /* default value */
  flex-grow: 1;
}
.registrationPage {
  position: relative;
  z-index: 1;
  width: 100%;

  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;

  background-color: $background;
}
.fullHeight {
  height: 100%;
  overflow-y: auto;
}
.logo {
  svg {
    height: 80px;
    display: block;
    margin: 0 auto 0;
  }
}
.small {
  font-size: $fontSmall;
}
.auxActions {
  display: flex;
  margin-top: 1rem;
  > div {
    flex-grow: 1;
    text-align: center;
  }
}
.termsLink {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: $vividpurple;
  outline: none;
}
.termsLink:hover,
.termsLink:focus,
.termsLink:active {
  text-decoration: underline;
}

.cancelButton {
  margin-top: 10px !important;
  border: #808080 solid 1px;
}

.zip {
  flex-basis: 5rem;
}
.alert {
  animation: pulse 1.5s infinite alternate;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  text-align: center;
  h2 {
    color: #fff;
    background-color: $warning;
    font-weight: 300;
    padding: 0.5rem;
    margin-bottom: 0;
    font-size: $fontSmall;
  }
  p {
    margin-top: 0;
    padding: 0.5rem;
  }

  @keyframes pulse {
    from {
      box-shadow: 0px 0px 10px rgba($warning, 0.2);
    }
    to {
      box-shadow: 0px 0px 40px rgba($warning, 1);
    }
  }
  // padding:1rem;
}
