@import '../../shared/styles/shared';

.screenToSmallModal {
  text-align: center;
  @media (min-width: 850px) {
    display: none;
  }
}

.spinnerBackground {
  background-color: $vividpurple !important;
}

.section {
  width: 90%;
  padding: 10px;
}

.implantSection {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  page-break-inside: avoid;
}

.report {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 200;
}

.header {
  font-size: 3rem;
  text-align: center;
}
.sectionHeader {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 5px;
  color: $vividpurple;
}
.fromToWrapper {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 5px;
  > * {
    width: 49%;
  }
  text {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.clinicWrapper {
  display: flex;
  > * {
    flex-grow: 1;
  }
}

.patientDataRow {
  display: flex;
  margin-top: 0.5rem;
  > * {
    flex-basis: 50%;
  }
}
.patientData {
  font-weight: 400;
}
.implantTable {
  text-align: center;
  width: 100%;
  th {
    text-align: center;
    font-weight: 200;
  }
}
.positionWrapper {
  display: flex;
}
.dentalNotifications {
  font-size: 1.5rem;
}
.dentalChart {
  width: 400px;
  margin-left: 100px;
}
.treatmentWrapper {
  display: flex;
  > * {
    width: 50%;
  }
}
.isqComment {
  margin-top: 10px;
  font-style: italic;
}
.treatmentSection {
  border-top: 1px solid $vividpurple;
  padding: 10px 0px;
}
.isqList {
  display: flex;
  justify-content: space-between;
}
.implantDataRow {
  display: flex;
  margin-top: 0.5rem;
  > * {
    flex-basis: 50%;
  }
}
.dataItem {
  flex-basis: 50%;
  display: flex;
  align-items: center;
}
.chartWrapper {
  width: 450px;
  margin-top: 10px;
}

.footer {
  display: none;
}
