html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
}

#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.push-right {
  float: right;
}

.push-left {
  float: left;
}

* {
  /* outline: 1px solid red;   */
}
@import url('leaflet.css');
