@import '../../../shared/styles/shared';
.section {
  justify-content: center;
  display: flex;
  margin: 1rem 0 0 0;
  width: 100%;
  @include for-tablet-landscape-up {
    max-width: 1050px;
    margin: 1rem auto 0;
  }
}

.section:first-child {
  margin-top: 0;
}
.section:last-child {
  padding-bottom: 10rem;
}
.inner {
  //max-width: 960px;
  //max-width: 960px;
  flex-basis: auto;
  flex-grow: 1;
  padding: 0 1rem;
  // box-sizing:border-box;
}
.flexContent {
  display: flex;
}
.row {
  display: flex;
  > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  margin-bottom: 1rem;
}
.rowItem {
  flex-grow: 1;
  /*padding: 0 1rem;*/
}

.fullWidth {
  max-width: 100% !important;
}

.pullRight {
  justify-content: flex-end;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .section {
    margin: 0px;
  }
  .inner {
    padding: 20px 10px;
  }
}
