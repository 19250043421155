.header {
  background-image: url('../../shared/images/help_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: top;
  height: 240px;
  color: #ffffff;
  .headerText {
    width: 500px;
    font-size: 32px;
    font-weight: 600;
    padding: 80px;
  }
}
.content {
  max-width: 800px;
}
.tiles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.title {
  font-size: 18px;
  font-weight: 400;
}

.hubspot {
  max-width: 300px;
}

.faqVideoWrapper {
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0rem;
  video {
    width: 80%;
  }
}

.faqImageWrapper {
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0rem;
  img {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .header {
    display: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 15px;
    .tiles {
      flex-direction: column;
    }
  }

  .faqTitle {
    margin-top: 23px;
  }

  .sendUsMessageTitle {
    margin-top: 23px;
  }
}
