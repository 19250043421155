@import '../../shared/styles/shared';

.header {
  background-image: url('../../shared/images/community_bg.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: top;

  height: 450px;
  color: $grey;

  svg {
    width: 200px;
    margin: auto;
    display: block;
  }

  .headerText {
    width: 200px;
    font-size: 3rem;
    font-weight: 600;
    padding: 80px;
  }

  .future {
    color: $purple;
  }
}

.headerThank {
  background-image: url('../../shared/images/community_bg.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: top;

  height: 250px;
  color: $grey;

  svg {
    width: 200px;
    margin: auto;
    display: block;
  }

  .headerText {
    width: fit-content;
    font-size: 3rem;
    font-weight: 600;
    padding: 80px;
  }
}

.contentThanks {
  max-width: 550px;
}

.formWrapper {
  display: flex;
  justify-content: center;
}
.hubspot {
  max-width: 300px;
  margin: 20px 10px;
}

.guideLines {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 10px;
  height: fit-content;
  h2 {
    color: $vividpurple;
  }
}

.promotionBox {
  background-color: $purple;
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: $radiusNorm;
  box-shadow: $toggledShadow;
  align-items: center;
  margin: 2rem auto;
  cursor: pointer;
  p {
    color: #fff;
    margin-right: 0.5rem;
  }
}

@media (max-width: 700px) {
  .header {
    display: none;
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
  }

  .contentBox {
    text-align: center;
    padding: 0 5px;
    margin-top: 10px;
  }

  .title {
    display: none;
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
  }
}
