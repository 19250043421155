@import '../../../src/shared/styles/shared';

.selectOptions {
  width: 50%;
}

.label {
  word-wrap: break-word;
  margin: 0px;
  font-size: $fontNorm;
  font-weight: 400;
  color: black;
  line-height: 1.5rem;
  display: block;
  margin-bottom: 0.25rem;
  &.disabled {
    opacity: 0.4;
  }
}

@media (max-width: 1000px) {
  .selectOptions {
    width: 70%;
  }
}

@media (max-width: 750px) {
  .selectOptions {
    width: 100%;
  }
}
