.edit {
  input {
    width: 100%;
  }
}

.button {
  cursor: pointer;
  color: rgb(0, 0, 255);
  padding: 3px;
}

td {
  // font-size: 0.8rem;
}
