@import '../../shared/styles/shared';
.app {
  display: flex;
  width: 100%;
  height: 100%;
  background: $background;
  overflow: hidden;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.main {
  //margin-left: 50px;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  background-color: $background;
  // flex-direction: column;
  height: calc(100% - 71px);
  margin-top: 71px;
  overflow: auto;
  position: relative;
  & > div:first-child {
    flex-grow: 1;
  }
}

@media (max-width: 750px) {
  .content {
    height: calc(100% - 50px);
    margin-top: 50px;
    overflow-x: hidden;
  }
}
