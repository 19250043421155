.dentalChart {
  padding: 0.5rem 0;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 750px) {
  .dentalChart {
    width: 1000px;
  }
}
