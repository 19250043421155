@import '../../shared/styles/shared';
.pageHeading {
  font-size: 1.25rem;
  font-weight: 600;
  color: $grey;
}
.pageHeadingTwo {
  font-size: $fontNorm;
  margin-bottom: 0.25rem;
}
.sectionDescription {
  font-weight: 400;
  margin-top: 0.25rem;
  display: block;
}
.stickyFilter {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  margin: -0.5rem;
  padding: 0.5rem;
  margin-top: 0rem;
  background-color: $background;
}
.addInstrument {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7px;
}

.container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.filterPanel {
  align-self: flex-end;
}

.settingsSection {
  padding-bottom: 50px;
}

@media (max-width: 750px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .stickyFilter {
    top: unset;
  }

  .addInstrument {
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .buttonRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    height: 104px;
    button {
      margin: 0px;
      width: 100%;
    }
  }
}
