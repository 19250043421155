@import '../../../shared/styles/shared';

.userRow {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $subtlegrey;
  box-shadow: 1px 1px 25px rgba(0, 0, 0, 0);
  transition: all 0.25s ease;
  &:first-child {
    border-top: 1px solid $subtlegrey;
    margin-top: -1rem;
  }
  &:last-child {
    border-bottom: none;
    margin-bottom: -1rem;
  }
  &:hover {
    background-color: $shadedwhite;
  }
  &.active {
    box-shadow: $toggledShadow;
    padding: 1rem 1rem;
    &:hover {
      background-color: #fff;
    }
  }
}
.actionBox {
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
  padding: 0.5rem 1rem 0;
  border-top: 1px solid $subtlegrey;
  a {
    font-size: $fontSmall;
  }
  .linkAction {
    line-height: 4.5rem;
    margin-top: 1rem;
    display: inline-block;
  }
  .warning {
    color: $warning;
  }
  .roleWrapper {
    width: 13rem;
  }
}
.header {
  padding: 1rem 0;
  display: flex;
  // justify-content: space-between;
  line-height: 2rem;
  > div {
    flex: 1;
  }
  .moreButton {
    flex: inherit;
    width: 2rem;
    text-align: center;
    font-size: 1.5rem;
    color: #4d4d4d;
    cursor: pointer;
    margin-bottom: -0.25rem;
    margin-top: 0.25rem;
    text-align: right;
    &:hover {
      color: $lightgrey;
    }
  }
  .heading {
    display: inline-block;
    font-weight: 300;
    padding: 0;
    margin: 0;
    flex-basis: 55%;
    .name {
      font-weight: 400;
    }
    .clinic {
      font-size: $fontNorm;
      margin-left: 10px;
    }
  }
  .lastSeen {
    text-align: right;
    font-size: $fontSmall;
    color: $lightgrey;
  }
  .email {
    display: block;
    color: $grey;
    font-size: $fontSmall;
    line-height: 0;
  }
  .badge {
    font-size: $fontSmall;
    padding: 0.5rem 0.75rem;
    background: transparent;
    color: $purple;
  }
}
