@import '../../../shared/styles/shared';

.pageSpinner {
  position: absolute;
  z-index: 1000;
  background: rgba(53, 17, 47, 0.87);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.spinner {
  width: 100%;
  text-align: center;
  white-space: nowrap;
}
.purple {
  background-color: $vividpurple !important;
}
.destructive {
  background-color: $destructive !important;
}

.white {
  background-color: white;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
