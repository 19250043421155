@import '../../../shared/styles/shared';

.button {
  padding: 0.75rem 1.25rem;
  min-height: 48px;
  background-color: $background;
  color: $greyText;
  border: none;
  border-radius: $radiusNorm;
  transition: all 0.3s ease;
  font-size: $fontMidi;
  font-weight: 400;
  margin: 0 1rem;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 0;
  min-width: 10rem;
  font-family: $fontFamily;
  //white-space: nowrap;

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  }
  &.leftAlign > div {
    justify-content: flex-start;
  }
  &.centered > div {
    justify-content: center;
  }
}
.largeButton {
  width: 160px;
  height: 200px;
  display: flex;
  flex-flow: column;
  padding: 1rem;
  box-sizing: border-box;
  align-items: center;
  text-align: center;

  background-color: #fff;
  color: $purple;
  border: none;
  border-radius: $radiusNorm;
  transition: box-shadow 0.3s ease;
  font-size: $fontNorm;
  font-weight: 500;
  margin: 0 1rem;
  outline: none;
  cursor: pointer;

  margin-left: 0;
  min-width: 10rem;
  font-family: $fontFamily;

  &.secondary {
    background-color: $background;
    color: $purple;
    &.shadow {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.02);
    }
    &:hover {
      box-shadow: 0px 0px 16px rgba(121, 49, 112, 0.24);
    }
  }

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  &.selected {
    border: 2px solid $purple;
  }
  .contentWrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
  .labelWrapper {
    display: flex;
    align-items: center;
    min-height: 40px;
  }
  img {
    max-width: 100%;
  }
}
.iconButton {
  min-width: auto;
  max-width: fit-content;
  max-height: 48px;
  .leftIcon {
    margin-right: 0;
  }

  .rightIcon {
    margin-left: 0;
  }
}
.primary {
  background-color: $purple;
  color: #fff;

  &:hover {
    background-color: $vividpurple;
  }
}

.light {
  padding: 0.3rem 0.75rem;
  border: 1px solid $purple;
  color: $purple;
}

.linkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: $vividpurple;
  outline: none;
}
.linkButton:hover,
.linkButton:focus,
.linkButton:active {
  text-decoration: underline;
}

.shadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
}

.button:last-child {
  margin-right: 0;
}

.button:first-child {
  margin-left: 0;
}

.button:hover {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
}

.full {
  width: 100%;
  margin: 0;
  margin-top: 1rem;
}

.big {
  font-size: $fontMidi;
}

.centered {
  justify-content: center;
  text-align: center;
}

.disabledPrimary {
  opacity: 0.3;
  cursor: default;
}

.disabledPrimary:hover {
  box-shadow: none;
  background-color: $vividpurple;
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.disabled:hover {
  box-shadow: none;
}

.leftIcon {
  font-size: 1.5rem;
  margin-right: 5px;
  width: 1.3rem;
  margin-left: -5px;
  position: relative;
  top: 1.5px;
}

.rightIcon {
  font-size: 1.5rem;
  margin-left: 5px;
  width: 1.3rem;
  margin-right: -5px;
  position: relative;
  top: 1.5px;
}

.hasIcon {
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.selectButton {
  display: flex;
  min-height: 48px;
  min-width: 48px;

  &.span label {
    flex-basis: 100%;
  }

  input {
    position: absolute;
    opacity: 0;
    width: 0px !important;
    height: 0px;
    cursor: pointer;
  }

  :first-child .selectOption {
    border-radius: $radiusNorm 0px 0px $radiusNorm;
  }

  :last-child .selectOption {
    border-radius: 0px $radiusNorm $radiusNorm 0px;
    margin-right: 0;
  }

  input:checked ~ .selectOption {
    background-color: $purple;
    color: #fff;
  }

  input:checked ~ .boolButton {
    background-color: $purple;
    color: #fff;
  }

  &.vertical {
    flex-direction: column;

    .selectOption {
      border-radius: $radiusNorm;
      margin-bottom: 0.5rem;
    }
  }
}

.boolButton {
  display: flex;
  justify-content: space-between;
  min-width: 335px;
  border-radius: $radiusNorm;
  padding: 0.75rem 1rem;
  background-color: #fff;
  border: none;
  transition: all 0.3s ease;
  font-size: $fontMidi;
  font-weight: 300;
  margin: 0 0.25rem;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 0;
  // width: 100%;
  box-sizing: border-box;
  min-height: 48px;
  // min-width: 48px;
  align-items: center;

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  }

  &.onWhite {
    background-color: $background;
  }
}

.selectOption {
  padding: 0.75rem 1rem;
  background-color: #fff;
  border: none;
  transition: all 0.3s ease;
  font-size: $fontMidi;
  font-weight: 300;
  margin: 0 0.25rem;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 0;
  box-sizing: border-box;
  display: flex;
  min-height: 48px;
  min-width: 48px;
  align-items: center;
  height: 100%;

  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  }

  &.onWhite {
    background-color: $background;
  }
}
.small {
  padding: 0.75rem 0.75rem;
  font-size: 0.9rem;
}

.secondary {
  color: $purple;
}

.white {
  background-color: #fff;
  &.primary {
    color: $purple;
  }
}

.selected {
  background-color: $purple;
}
.spinnerBackground {
  background-color: $purple;
}

@media (max-width: 700px) {
  .button {
    padding: 5px 10px;
  }

  .selectOption {
    padding: 0px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
