@import '../../../shared/styles/shared';
.tabWrapper {
  width: 100%;
  // background-color:#fff;
  // display:flex;
  // justify-content: center;
}
.tabList {
  background-color: #fff;
  // padding: 0;
  display: flex;
  // margin-left:1rem;
  // margin-right:1rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  &.marginBottom {
    margin-bottom: 1.5rem;
  }
  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    width: 200vw;
    margin-left: -100vw;
    z-index: -1;
  }
  li {
    padding: 1.75rem 2rem;
    color: $grey;
    list-style: none;
    font-size: $fontNorm;
    cursor: pointer;
    text-transform: uppercase;
    &.active {
      color: $vividpurple;
      background-color: $lightgrey;
      // border-bottom: 4px solid $vividpurple;
    }
    &:hover {
      color: $vividpurple;
      background-color: $shadedwhite;
    }
    &:focus {
      outline: none;
    }
  }
}

.tabDisabled {
  opacity: 0.5;
}
