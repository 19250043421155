@font-face {
  font-family: 'Osstell Icons';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/Osstell_Icons_Regular/OsstellIconRegular.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Osstell_Icons_Regular/OsstellIcon_201126.otf')
      format('opentype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Osstell Icons Light';
  font-weight: 200;
  font-style: normal;
  src: url('../fonts/Osstell_Icons_Light/OsstellIconLight.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../fonts/Osstell_Icons_Light/OsstellIcon-Light_201126.otf')
      format('opentype'); /* Safari, Android, iOS */
}

i,
.osstell-icons {
  font-family: 'Osstell Icons';
  font-weight: 200;
  font-style: normal;

  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');
