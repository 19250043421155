@import '../../shared/styles/shared';

.deviceTable {
  margin-bottom: 4rem;
}
.downloadFirmwareButton {
  margin-right: 10px;
}

.noteContainer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.spinnerBackground {
  background-color: $vividpurple !important;
  margin-top: 30px !important;
}
.icon {
  width: 1%;
  vertical-align: middle;
  i {
    font-size: 3rem;
    line-height: 0;
    display: block;
    position: relative;
    top: 3px;
  }
}
.deviceIcon {
  svg {
    height: 25px;
    width: 50px;
  }
}

.buttonWrapper {
  display: flex;
}
.iconWrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.labelWrapper {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.bigButton {
  width: 160px;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding: 1rem;
  box-sizing: border-box;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: $purple;
  margin-right: 1rem;
  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  }
}
.dropdownToggle {
  display: flex;
  height: 50px;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
}
.downloadSections {
  display: flex;
  justify-content: space-between;
}

.downloadSection {
  // display: flex;
  flex-basis: calc(50% - 0.5rem);
}
.downloadDescription {
  margin-top: 1rem;
  // display: flex;
  color: $grey;
  font-size: $fontSmall;
  line-height: 1.5;
  // align-items: center;
}

.modalContent {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.modalButtonWrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.mobileRow {
  display: none;
}

@media (max-width: 750px) {
  .mobileRow {
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 0px 0px 10px;
    .rowInfoContainer {
      padding: 10px 0px;
      .rowInMobileRow {
        display: flex;
        justify-content: flex-start;
        span:last-child {
          margin-left: 4px;
        }
      }
    }
    .deviceButton {
      border-left: 2px solid #edeef7;
      width: 30px;
      .dropdownToggle {
        height: 100%;
        i {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }
      }
    }
  }

  .modalButtonWrapper {
    flex-direction: column;
    button {
      margin: 0px !important;
    }
    button:last-child {
      margin-top: 10px !important;
    }
  }

  .downloadSections,
  .dowloadHeader,
  .deviceTable {
    display: none;
  }
}
