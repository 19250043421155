@import '../../../shared/styles/shared';

.headerRow {
  margin-bottom: 1.5rem;
}

.marginTop {
  margin-top: 1rem;
}

.tabList {
  // background-color:#eee;
  padding: 0;
  display: flex;
  // margin-left:1rem;
  // margin-right:1rem;
  li {
    padding: 1rem 1rem;
    color: $grey;
    outline: 0;
    list-style: none;
    font-size: $fontNorm;
    cursor: pointer;
    border-bottom: 4px solid transparent;
    transition: 0.5s ease;
    &.active {
      color: $vividpurple;
      border-bottom: 4px solid $vividpurple;
    }
    &:hover {
      border-bottom: 4px solid $subtlegrey;
    }
  }
}

.tabContent {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.searchTypeDropdown {
  width: 250px;
}

.tr {
  white-space: normal;
}

.dateTD {
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
}

.dataTD {
  word-wrap: break-word;
  max-width: 450px;
}
