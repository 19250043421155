@import '../shared/styles/shared';

.app {
  display: flex;
  width: 100%;
  height: 100%;
  background: $background;
  overflow: hidden;
  position: fixed;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.confirmContent {
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 1rem;
  }
}

.smartpegtool {
  display: flex;
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
.main {
  //margin-left: 50px;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  background-color: $background;
  // flex-direction: column;
  height: calc(100% - 71px);
  margin-top: 71px;
  overflow: auto;
  position: relative;
  & > div:first-child {
    flex-grow: 1;
  }
}

:global(.example-enter) {
  opacity: 0.01;
}

:global(.example-enter.example-enter-active) {
  opacity: 1;
  transition: opacity 700ms ease-in;
}

:global(.example-leave) {
  opacity: 1;
}

:global(.example-leave.example-leave-active) {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

:global(.example-appear) {
  opacity: 0.01;
}

:global(.example-appear.example-appear-active) {
  opacity: 1;
  transition: opacity 0.7s ease-in;
}

:global(.cross-fade-leave) {
  opacity: 1;
}

:global(.cross-fade-leave.cross-fade-leave-active) {
  opacity: 0;
  transition: opacity 0.15s ease-in;
}

:global(.cross-fade-enter) {
  opacity: 0;
}

:global(.cross-fade-enter.cross-fade-enter-active) {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

:global(.cross-fade-height) {
  transition: height 0.5s ease-out;
}

:global(.pop-enter) {
  transform: scale(0);
}

:global(.pop-enter.pop-enter-active) {
  transform: scale(1);
  transition: all 0.3s ease-out;
}

:global(.pop-leave) {
  transform: scale(1);
}

:global(.pop-leave.pop-leave-active) {
  transform: scale(0);
  transition: all 0.2s ease-out;
}

:global(.pop-appear) {
  transform: scale(0);
}

:global(.pop-appear.pop-appear-active) {
  transform: scale(1);
  transition: all 0.3s ease-out;
}
