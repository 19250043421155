@import '../../../shared/styles/shared';
.h1 {
  font-size: 3rem;
  font-weight: 100;
  color: $purple;
}

.h2 {
  font-weight: 600;
  font-size: 1.15rem;
}

.h3 {
  font-weight: 600;
  font-size: 1rem;
}

.h4 {
  font-weight: 500;
  font-size: 0.9rem;
}

.smallHeader {
  font-weight: 400;
  font-size: 0.6rem;
  text-transform: uppercase;
  color: $vividpurple;
  letter-spacing: 0.25px;
}
