@import '../../shared/styles/shared';

.smartPegToolPage {
  position: relative;
  z-index: 1;
  width: 100%;

  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
.fullHeight {
  height: 100%;
  overflow-y: auto;
}
