.outer {
  width: 100%;
  max-width: 100vw;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.inner {
  width: fit-content;
  min-width: 100%;
  transform-origin: top left;
}
