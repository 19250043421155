@import '../../../shared/styles/shared';

.panelWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  // flex-grow: 1;
  margin: -0.5rem;
}

.panel {
  background-color: #fff;
  border-radius: $radiusNorm;
  padding: 1rem;
  box-sizing: border-box;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.499rem;
  margin-right: 0.499rem;
  flex-basis: calc(33.3333333333% - 1rem);
  max-width: calc(33.3333333333% - 1rem);
  transition: all 0.3s ease;
  position: relative;
}

.panelColor {
  background-color: #fff;
}

.square:before {
  content: '';
  float: left;
  padding-top: 100%;
}

.full {
  flex-basis: 100% !important;
  max-width: 100%;
}

.content {
  box-sizing: border-box;
  height: auto;
}

.insightsBorder {
  border-bottom: none;
}
.h2 {
  margin: 0;
}

.header {
  margin: 0;
  display: flex;
  h2 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    color: $purple;
  }
}

.buttonWrapper {
  margin-left: auto;
  margin-right: -1rem;
  margin-top: -0.75rem;
  display: flex;
}

.optionsWrapper {
  margin-left: auto;
  margin-right: -1rem;
  margin-top: -0.75rem;
  display: flex;
  // &.active{
  // 	.optionList{
  // 		right:0;
  // 		box-shadow: 0px 0 20px rgba(0,0,0,0.15);
  // 	}
  // }
}

.optionButton {
  margin-left: auto;
  font-size: 1.5rem;
  padding: 1rem 1rem 0 1rem;
  color: $midgrey;
  position: relative;
  z-index: 200;
  cursor: pointer;

  &:hover {
    background-color: $subtlegrey;
    i {
      color: $grey;
    }
  }
}

.optionList {
  width: 200px;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  right: -200px;
  top: 0;
  z-index: 100;
  box-shadow: 0px 0 20px rgba(0, 0, 0, 0);
  // padding-top:4rem;
  box-sizing: border-box;
  &.active {
    // .optionList{
    right: 0;
    box-shadow: 0px 0 20px rgba(0, 0, 0, 0.15);
    // }
    li {
      cursor: pointer;
    }
  }
  ul {
    padding: 0;

    border-top: 1px solid $subtlegrey;
    margin: 0;
    &:first-child {
      border-top: none;
    }
    li {
      font-size: $fontSmall;
      text-decoration: none;
      list-style: none;
      font-weight: 300;
      padding: 0.75rem 2rem;

      &:hover {
        background-color: $shadedwhite;
        color: $vividpurple;
      }
    }
  }
}

@media (max-width: 480px) {
  .mobileColumn {
    flex-basis: auto !important;
    max-width: 100% !important;
    height: 50px !important;
    margin-left: 0px;
    margin-right: 0px;
  }
}
