@import '../../shared/styles/shared';

.header {
  position: fixed;
  height: 71px;
  background: #fff;
  display: flex;
  z-index: 1000;
  width: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  color: $purple;
  border: 1px solid #ededed;
}

.disabled {
  color: $lightgrey;
  svg {
    fill: var(--color-lightgrey) !important;
    stroke: var(--color-lightgrey) !important;
  }
}

.titleDiv {
  .title {
    font-size: 1.2rem;
    text-align: center;
  }
  .siteNote {
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  flex: 1;
}

.buttons {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  &:first-child > span {
    margin-left: 71px;
    margin-right: auto;
    display: flex;
    height: 100%;
  }
  &:last-child > span {
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.navItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 71px;
  text-align: center;
  position: relative;
  cursor: pointer;
  &.right {
    border-left: 1px solid #ededed;
  }
  &.left {
    border-right: 1px solid #ededed;
  }
  i {
    font-size: 2rem;
  }
  svg {
    margin: 0 auto;
    fill: var(--color-purple);
    stroke: var(--color-purple);
    height: 1.75rem;
    padding-bottom: 0.25rem;
  }
  .label {
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    left: 0px;
    bottom: 5px;
    margin: 0 3px;
  }
}

.spinnerBackground {
  background-color: $purple;
  margin: 11px 2px;
}

.toggler {
  display: block;
  @media (max-width: 1200px) {
    display: none;
  }
}

@media (max-width: $consultationDisabledAt) {
  .consultationButton {
    display: none;
  }
}

@media (max-width: 750px) {
  .skipTitleOnMobile {
    display: none;
  }
  .left,
  .label {
    display: none;
  }

  .navItem {
    min-width: 50px;
  }

  .header {
    height: 50px;
  }

  .buttons {
    &:first-child > span {
      margin-left: 50px;
    }
  }
}

@media (max-width: 400px) {
  .titleDiv {
    .siteNote {
      width: 112px;
    }
  }
}
