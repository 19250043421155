$my-color: #347698;
// Colors
$my-dark: #190b21;
$purple: #822973;
$black: #0000;
$vividpurple: #a62193;
//$darkpurple:#2f2438;
$darkpurple: #4a1457;
$lightpurple: #d1aacb;

// $ISQhigh: #54da94; // old
// $ISQmedium: #fbb23a; // old
// $ISQlow: #bd004d; //old
$ISQhigh: #5fd99b;
$ISQmedium: #e8e82b;
$ISQlow: #d6266a;
$ISQneutral: #615f96;
$ISQneutralMuted: #b9b8d4;

$warning: $ISQlow;

$online: $ISQhigh;
$offline: $ISQlow;

$shadedwhite: #fbfbfb;
$subtlegrey: #ededed;
$lightgrey: #dcdcdc;
$midgrey: #bbb;
$grey: #3d3d4d;
$greyText: #636273;
$tintedLightgrey: #f4f5fc;
$tintedgrey: #d8d8eb;

$background: #edeef7;

$inputDark: $background;
$radiusNorm: 8px;

$insightsPale: rgba(255, 255, 255, 0.1);
//Font sizes
$fontMini: 0.6rem;
$fontSmall: 0.8rem;
$fontNorm: 1rem;
$fontMidi: 1.125rem;
$fontBig: 2rem;
$fontMaxi: 3rem;
$fontMega: 4rem;

$fontFamily: 'Source Sans Pro';

$warning: $ISQlow;

$toggledShadow: 1px 1px 25px rgba(0, 0, 0, 0.1);

$consultationDisabledAt: 1000px;

$primary: #c749b3;
$destructive: #ef4444;

:export {
  purple: $purple;
  vividpurple: $vividpurple;
  lightpurple: $lightpurple;
  ISQhigh: $ISQhigh;
  ISQmedium: $ISQmedium;
  ISQlow: $ISQlow;
  ISQneutral: $ISQneutral;
  ISQneutralMuted: $ISQneutralMuted;
  warning: $warning;
  shadedwhite: $shadedwhite;
  subtlegrey: $subtlegrey;
  lightgrey: $lightgrey;
  midgrey: $midgrey;
  grey: $grey;
  greyText: $greyText;
  tintedLightgrey: $tintedLightgrey;
  tintedgrey: $tintedgrey;
  consultationDisabledAt: $consultationDisabledAt;
  primary: $primary;
  destructive: $destructive;
}

:root {
  --color-purple: #{$purple};
  --color-border: #615f96;
  --color-border-20: rgba(97, 95, 150, 0.2);
  --color-vividpurple: #{$vividpurple};
  --color-lightpurple: #{$lightpurple};
  --color-ISQhigh: #{$ISQhigh};
  --color-ISQmedium: #{$ISQmedium};
  --color-ISQlow: #{$ISQlow};
  --color-ISQneutral: #{$ISQneutral};
  --color-ISQneutralMuted: #{$ISQneutralMuted};
  --color-warning: #{$warning};
  --color-shadedwhite: #{$shadedwhite};
  --color-subtlegrey: #{$subtlegrey};
  --color-lightgrey: #{$lightgrey};
  --color-midgrey: #{$midgrey};
  --color-grey: #{$grey};
  --color-greyText: #{$greyText};
  --color-tintedLightgrey: #{$tintedLightgrey};
  --color-tintedgrey: #{$tintedgrey};
  --color-primary: #{$primary};
  --color-destructive: #{$destructive};
}
