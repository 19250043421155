@import '../../shared/styles/shared';

.ISQitem {
  width: 100%;
  // flex-grow: 1;
  .label {
    font-size: 14px;
    color: black;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
  .row {
    display: flex;
    height: 48px;
    margin-bottom: 0.5rem;
    font-weight: 400;
    .frame {
      background-color: $background;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      .isq {
        border-radius: 8px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 1.4rem;
        color: white;
        font-weight: 700;
      }
      .type {
        flex: 1;
        padding-left: 1rem;
        padding-right: 0.5rem;
      }
      .blmd {
        flex: 1;
        padding-left: 1rem;
        padding-right: 0.5rem;
      }
      .levelInfo {
        flex: 1;
        white-space: nowrap;
      }
      .date {
        padding-left: 0.5rem;
        padding-right: 1rem;
      }
    }
    .edit {
      color: $purple;
      margin-left: 0.5rem;
      padding: 0 1.5rem;
      flex-grow: 0;
      font-family: 'Osstell Icons';
      font-weight: 200;
      font-style: normal;
      font-size: 1.5rem;
      padding-top: 0.3rem;
      border-width: 0;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.low {
  background-color: $ISQlow;
}
.mid {
  background-color: $ISQmedium;
}
.high {
  background-color: $ISQhigh;
}
.neutral {
  background-color: $ISQneutral;
}
