@import '../../shared/styles/shared';

.subStatus {
  font-size: 0.8rem;
  color: #bbb;
  font-weight: 300;
}

.subDate {
  font-size: 0.8rem;
  font-weight: 300;
  color: #bbb;
  margin-left: 0.5rem;
}

.statusMarker {
  width: 0.75rem;
  height: 0.75rem;
  float: left;
  margin-top: 0.4rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: #4d4d4d;
}

.high {
  background-color: $ISQhigh;
}

.medium {
  background-color: $ISQmedium;
}

.low {
  background-color: $ISQlow;
}

.tr {
  cursor: pointer;
}

.tdButton {
  text-align: center;
  border-left: 2px solid $background;
  font-size: 1.5rem;
  width: 1%;
  white-space: nowrap;
}

.button {
  color: $purple;
}

.consultationTh {
  padding: 1rem 1.25rem 0.25rem;
  font-weight: 700;
}

.mobileRow {
  display: none;
}

// Consultation not available on this width
@media (max-width: $consultationDisabledAt) {
  .tdButton,
  .consultationTh {
    display: none;
  }

  .lastChangedTd {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

@media (max-width: 700px) {
  .tr,
  .tableHead {
    display: none;
  }

  .mobileRow {
    display: block;
    background-color: white;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
    padding: 5px 10px;
  }

  .mobileRowOne {
    display: block;
    width: 100%;
    height: 20px;
    .mobilePatienId {
      display: inline-block;
    }
    .mobileLastChanged {
      float: right;
    }
  }
}
