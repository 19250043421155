@import '../../shared/styles/shared';

.tile {
  padding: 0;
  border-radius: 5px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  .header {
    width: 100%;
    text-align: center;
  }
  .isq {
    width: 100%;
    text-align: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.medium {
  flex-basis: calc(50% - 0.5rem);
  max-width: calc(50% - 0.5rem);
  .header {
    font-size: 1.125rem;
    line-height: 2rem;
    font-weight: 700;
  }
  .isq {
    height: 150px;
    font-size: 6rem;
  }
}

.isqInput {
  display: block;
  width: 100%;
  max-width: 200px;
  font-size: inherit;
  padding: 1rem;
  box-sizing: border-box;
  border: none;
  text-align: center;
  margin: auto auto;
  &:focus {
    outline-color: $ISQneutral;
  }
}

.colorIndicator {
  height: 32px;
  margin: -1px;
}

.low {
  border: 4px solid $ISQlow;
  color: $ISQlow;
  .colorIndicator {
    background-color: $ISQlow;
  }
  .isqInput {
    color: $ISQlow;
    &:focus {
      outline: none;
    }
  }
}
.mid {
  border: 4px solid $ISQmedium;
  color: $ISQmedium;
  .colorIndicator {
    background-color: $ISQmedium;
  }
  .isqInput {
    color: $ISQmedium;
    &:focus {
      outline: none;
    }
  }
}
.high {
  border: 4px solid $ISQhigh;
  color: $ISQhigh;
  .colorIndicator {
    background-color: $ISQhigh;
  }
  .isqInput {
    color: $ISQhigh;
    &:focus {
      outline: none;
    }
  }
}
.neutral {
  border: 4px solid $ISQneutral;
  color: $ISQneutral;
  .colorIndicator {
    background-color: $ISQneutral;
  }
  .isqInput {
    color: $ISQneutral;
    &:focus {
      outline: none;
    }
  }
}

.noMeasurement {
  border: 4px solid $background;
  color: $purple;
  .colorIndicator {
    background-color: $background;
  }
  .isqInput {
    color: $background;
  }
}

.active {
  border: 4px dashed $purple;
}
.inactive {
  margin: 0px;
}
.flash {
  animation: pulse 0.3s 2 alternate;
}

@keyframes pulse {
  from {
    box-shadow: 0px 0px 10px rgba($warning, 0.2);
  }
  to {
    box-shadow: 0px 0px 40px rgba($warning, 1);
  }
}

@media (max-width: 750px) {
  .isq {
    font-size: 4rem !important;
    height: 111px !important;
  }
}
