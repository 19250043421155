@import '../../shared/styles/shared';

.tile {
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: $radiusNorm;
  box-shadow: $toggledShadow;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  min-height: 150px;
}

.newsContent {
  text-align: center;
  width: 60%;
  font-weight: 400;
  font-size: 14px;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .removeBackgroundOnMobile {
    background-image: none !important;
    background-color: white;
    .newsContent {
      width: 100%;
    }
  }
}
