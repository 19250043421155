@import '../../shared/styles/shared';

.button {
  position: fixed;
  height: 69px;
  width: 69px;
  line-height: 69px;
  text-align: center;
  font-size: 2rem;
  z-index: 10000;
  background: #fff;
  cursor: pointer;
  border: 1px solid #ededed;
}

.nav {
  position: fixed;
  height: 100%;
  max-width: 0px;
  font-size: 1.1rem;
  font-weight: 300;
  transition: all 0.35s ease;
  overflow: hidden;
  background: $darkpurple;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0px 20px 20px 0px;
}
.overlay {
  position: fixed;
  height: 100%;
  width: 0px;
  z-index: 10000;
}

.overlay.expanded {
  width: 100%;
}

.nav.expanded {
  max-width: 250px;
  overflow-y: auto;
}

.nav.expanded .navItem .title,
.nav:hover .navItem .title {
  opacity: 1;
}

.logo {
  background: #402a49;
  height: 71px;
}

.navItem {
  padding: 0.8rem 13px;
  background: inherit;
  border: none;
  color: $lightpurple;
  white-space: nowrap;
  cursor: pointer;
}

.navItemActive {
  background-color: $purple;
}

.navItem i {
  font-size: 1.5rem;
  display: block;
  float: left;
  width: 2rem;
}

.navItem:hover {
  background: #402a49;
}

.title {
  transition: all 0.35s ease;
  margin-left: 0.5rem;
  opacity: 0;
  font-weight: 300;
}

.navItemIcon {
  composes: osstell-icons from '../../shared/styles/fonts.css';
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.divider {
  width: 80%;
  margin: 20px auto;
  border-top: 1px solid $lightpurple;
}

.toggler {
  display: none;
  @media (max-width: 1200px) {
    display: block;
    width: 100%;
  }
}

@media (max-width: 750px) {
  .button {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
