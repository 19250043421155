@import '../../shared/styles/shared';

.spinnerBackground {
  background-color: $vividpurple !important;
}

.section {
  width: 90%;
  padding: 10px;
}

.implantSection {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  page-break-inside: avoid;
}

.report {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 200;
}

.header {
  font-size: 3rem;
  text-align: center;
}
.sectionHeader {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 5px;
  color: $vividpurple;
}
.fromToWrapper {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-bottom: 5px;
  > * {
    width: 49%;
  }
  .fromTo {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.clinicWrapper {
  display: flex;
  justify-content: space-between;
  > * {
    flex-grow: 0.4;
  }
}

.patientDataRow {
  display: flex;
  margin-top: 0.5rem;
  > * {
    flex-basis: 50%;
  }
}
.patientData {
  font-weight: 400;
}
.implantTable {
  text-align: center;
  width: 100%;
  th {
    text-align: center;
    font-weight: 200;
  }
}
.positionWrapper {
  display: flex;
}
.dentalNotifications {
  font-size: 1.5rem;
}
.dentalChart {
  width: 500px;
  margin-left: 100px;
}
.treatmentWrapper {
  display: flex;
  > * {
    width: 50%;
  }
}
.isqComment {
  margin-top: 10px;
  font-style: italic;
}
.treatmentSection {
  border-top: 1px solid $vividpurple;
  padding: 10px 0px;
}
.isqList {
  display: flex;
  justify-content: space-between;
}
.implantDataRow {
  display: flex;
  margin-top: 0.5rem;
  > * {
    flex-basis: 50%;
  }
}
.dataItem {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  > * {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.chartWrapper {
  width: 450px;
  margin-top: 10px;
  padding: 20px;
}

.footer {
  display: none;
}

.label {
  font-size: 16px;
  margin: 5px 0px 0px 1px;
}

.infoPage {
  color: black;
  font-size: 18px;
  margin: 0 auto;
  width: 860px;
  .mainHeader {
    display: block;
    font-size: 60px;
    text-align: center;
    margin-top: 0px;
  }

  .infoText1 {
    margin: 0 auto;
    width: 860px;
  }

  .checkContainer {
    margin: 0 auto;
    width: 860px;
    display: flex;
    align-items: center;
    height: 40px;
    div {
      width: 40px;
      display: flex;
      align-items: center;
    }
    img {
      width: 30px;
      height: 32px;
    }
  }

  .textAndImgContainer {
    display: flex;
    width: 860px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    div {
      width: 300px;
      img {
        max-width: 100%;
      }
    }
    p {
      width: 541px;
    }
  }

  .secondHeader {
    display: block;
    font-size: 40px;
    margin: 10px 0px 0px 0px;
  }

  .isqScaleContainer {
    width: 602px;
    height: 445px;
    margin: 0 auto;
    width: 70%;
    img {
      max-width: 100%;
    }
  }
  .scientificReferences {
    color: black;
    font-size: 14px;
    font-weight: 300;
  }

  ol {
    font-size: 11px;
  }

  .contact {
    background-color: $darkpurple;
    color: white;
    width: 90%;
    margin: 20px auto 10px auto;
    font-size: 24px;
    font-weight: 400;
    border-radius: 20px;
    padding: 10px;
    span {
      text-align: center;
      display: block;
    }
  }
}
