@import '../../shared/styles/shared';

.exportSection {
  .panelWrapper {
    align-items: center;
    height: 100%;
  }
  .panel {
    padding: 1rem 2rem;
  }
  .dismissAction {
    float: right;
    font-size: 2rem;
    margin-top: -1.5rem;
    margin-right: -1.5rem;
    color: $lightgrey;
    cursor: pointer;
    &:hover {
      color: $grey;
    }
  }
}

.show {
  opacity: 1;
  transition: all 0.35s ease-out;
  .panel {
    transform: scale(1);
  }
}
.hide {
  display: none;
  opacity: 0;
  transition: all 0.35s ease-out;
  .panel {
    transform: scale(0.8);
  }
}
