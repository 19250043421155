@import '../../shared/styles/shared';
// .modalPanel {
//   flex-basis: initial;
//   max-width: 1050px;
//   max-height: 95%;
//   overflow-y: auto;
//   // transition: all .35s ease-out;
//   // transform: scale(1);
//   // max-width: 600px;
//   // min-width: 320px;
//   // flex-basis: 100%;
// }
.content {
  display: flex;
  padding: 1rem 3rem;
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    min-width: 20rem;
    > * {
      // flex-basis: 50%;
    }
    &:not(:first-child) {
      border-left: 1px solid $background;
      padding-left: 4rem;
    }
    &:not(:last-child) {
      padding-right: 4rem;
    }
    h2 {
      margin-top: 0px;
    }
  }
}

.scanRow {
  margin-top: 10px;
  display: flex;
  flex-direction: row !important;
  align-items: flex-end;
  .scanButton {
    max-width: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(237, 238, 247);
    height: 48px;
    border-radius: 8px;
    margin-bottom: 8px;
    margin-left: 5px;
    .icon {
      display: block;
      top: 2px;
    }
  }
}

.mandatoryFields {
  text-align: right;
  padding-right: 3rem;
}

.measurements {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timestamp {
  width: 100%;
  display: flex;
  justify-content: center;
}

.date {
  width: 50%;
  display: flex;
  justify-content: center;
  padding: 0;
}

.buttons {
  margin-top: 15px;
  width: calc(100% - 3rem);
  display: flex;
  justify-content: flex-end;
}

.instrumentStatus {
  padding: 5px;
  border: 2px solid $purple;
  border-radius: 3px;
  font-size: $fontNorm;
  font-weight: 200;
}
.online {
  border: 4px dashed $purple;
}

.abutmentNote {
  font-size: $fontSmall;
  padding: 0.5rem;
  font-style: italic;
  max-width: fit-content;
}

.measurementWrapper {
  display: flex;
  flex-direction: column;
}

.isqWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.isqInfoIcon {
  margin-top: 0.5rem;
}

@media (max-width: 950px) {
  .content {
    padding: 0px 0px;
    .column {
      &:not(:first-child) {
        padding-left: 10px;
      }
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: 750px) {
  .content {
    flex-direction: column;
    padding: 0px;
    height: 100%;

    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      min-width: unset;
      &:not(:first-child) {
        border-left: unset;
        padding-left: 0;
      }
      &:not(:last-child) {
        padding-right: 0;
      }
    }
  }
  .buttons {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
}

@media (max-width: 450px) {
  .buttons {
    flex-direction: column;
  }
  button:first-child {
    margin: 0px 0px 10px 0px;
  }
}
