@import '../../shared/styles/shared';

.filterBar {
  display: flex;
  background-color: #fff;
  border-radius: $radiusNorm;
  padding: 1rem;
  .filterGroup {
    flex-basis: 50%;
    margin: 0 1rem;
  }
}

@media (max-width: 750px) {
  .filterBar {
    display: flex;
    flex-direction: column;
    padding: 5px;
    .filterGroup {
      margin: 0px;
    }
  }
}
