.tooth {
  height: 90px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-width: 20px;
  &.upper {
    background-position: bottom;
  }
  &.lower {
    background-position: top;
  }
  &.implant {
    height: 60px;
    background-position: bottom;
    display: flex;
    justify-content: center;
    &.upper {
      margin-bottom: 25px;
      margin-top: 5px;
    }
    &.lower {
      margin-bottom: 5px;
      margin-top: 25px;
    }
    .abutmentInner {
      width: 100%;
      height: 30px;
      /* display: none; */
      background-position: top;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      top: 59px;
      max-width: 28px;
    }
  }
}

@media (max-width: 750px) {
  .tooth {
    height: 50px;
    &.implant {
      height: 35px;
      margin: 0px;
      &.upper {
        margin-bottom: 10px;
        margin-top: 5px;
      }
      &.lower {
        margin-bottom: 5px;
        margin-top: 10px;
      }
      .abutmentInner {
        width: 100%;
        height: 10px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        top: 35px;
        max-width: 28px;
      }
    }
  }
}
