@import '../../../shared/styles/shared';

$padding: 1rem;
$border: 1px solid white;
$hoverborder: 1px solid $midgrey;
$labelHeight: 1.5rem;
$inputHeight: 48px;
.inputWrapper {
  // display:flex;
}
.inputRow {
  display: flex;
  flex-direction: column;
  @include for-tablet-portrait-up {
    flex-direction: row;
    > * {
      flex-basis: 100%;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &.half {
      width: 50%;
      padding-right: 0.5rem;
      box-sizing: border-box;
    }
  }
}

.inputGroup {
  .h2 {
    font-size: 1.125rem;
    font-weight: 500;
    // border-bottom: 1px solid $lightgrey;
    padding-bottom: 0.5rem;
    // margin-bottom: 0;
  }
  .p {
    font-size: $fontSmall;
  }
  margin-bottom: 1.5rem;
}

.inputSubGroup {
  display: flex;
  flex-wrap: wrap;
}

.inputItem {
  // margin-right:1rem;
  // float:left;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // flex-basis: auto;
  min-width: 5rem;

  font-size: $fontMidi;
  @media screen and (max-width: 580px) {
    // font-size: 3vw;
  }
  border-radius: 0;
  border: none;

  // min-width:130px;
  // width: 100%;
  transition: all 0.3s ease;
  & > div {
    flex-grow: 1;
  }
  &:focus,
  &:active {
    outline: none;
    // box-shadow: 0px 0px 10px 0 rgba(0,0,0,.15);
  }
  input,
  textarea,
  select,
  .select {
    &::placeholder {
      color: $midgrey;
    }
    &:hover,
    &:focus,
    &:active {
      outline: none;
      border: none;
      box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);
    }

    display: block;
    width: 100%;
    font-size: inherit;
    padding: 0.75rem $padding;
    box-sizing: border-box;
    border: none;
    border-radius: $radiusNorm;
    font-family: $fontFamily;
    font-weight: 300;
    min-height: $inputHeight;
    transition: all 0.3s ease;
  }
  &.dark {
    input {
      background-color: $inputDark;
    }
    .select {
      background-color: $inputDark;
    }
  }
  &.light {
    label {
      color: $lightgrey;
    }
  }
  &.marginTop {
    margin-top: $labelHeight;
  }
  &.marginBottom {
    margin-bottom: 0.5rem;
  }
  &.small {
    // padding: 0.5rem;
    input,
    textarea {
      padding: 0.5rem;
    }
    .button {
      line-height: 1;
    }
    .selectWrapper {
      &:after {
        right: 2.5rem;
      }
    }
    .select {
      padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    }
    .searchBox {
      i {
        font-size: 1.75rem;
        line-height: 2.5rem;
      }
    }
  }
  .checkBox {
    display: flex;
    align-items: center;
    cursor: pointer;
    &.margins {
      margin: 1rem 0;
    }
    input {
      width: auto;
      //float: left;
      margin-right: 0.75rem;
      //margin-top: 0.25rem;
      cursor: pointer;
    }
  }
  .button {
    border: $border;
    box-shadow: none;
    // background-color:#fff;
    &:hover {
      box-shadow: $toggledShadow;
      // border:$hoverborder;
    }
    display: block;
    line-height: 2;
  }
  .searchBox {
    i {
      float: left;
      display: block;
      font-size: 2.25rem;
      line-height: 3.5rem;
      width: 0;
      position: relative;
      left: 0.5rem;
      color: $midgrey;
    }
    input {
      padding-left: 3rem;
    }
  }
}

.inputItemNoBorder {
  // margin-right:1rem;
  // float:left;
  //margin-bottom: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-size: $fontMidi;
  border-radius: 0;
  border: none;

  // min-width:130px;
  // width: 100%;
  transition: all 0.3s ease;

  &:focus,
  &:active {
    outline: none;
    // box-shadow: 0px 0px 10px 0 rgba(0,0,0,.15);
  }
  input,
  textarea {
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);
    }
    display: block;
    width: 100%;
    font-size: inherit;
    padding: $padding;
    box-sizing: border-box;
    border: none;
  }
  &.light {
    label {
      color: $lightgrey;
    }
  }
  &.small {
    // padding: 0.5rem;
    input,
    textarea {
      padding: 0.5rem;
    }
    .button {
      line-height: 1;
    }
    .selectWrapper {
      &:after {
        right: 2.5rem;
      }
    }
    .select {
      padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    }
    .searchBox {
      i {
        font-size: 1.75rem;
        line-height: 2.5rem;
      }
    }
  }
  &.noPadding {
    padding: 0px;
  }
  .checkBox {
    &.margins {
      margin: 1rem 0;
    }
    input {
      width: auto;
      float: left;
      margin-right: 0.75rem;
      margin-top: 0.25rem;
      cursor: pointer;
    }
  }
  .button {
    border: $border;
    box-shadow: none;
    // background-color:#fff;
    &:hover {
      box-shadow: $toggledShadow;
      // border:$hoverborder;
    }
    display: block;
    line-height: 2;
  }
  .searchBox {
    i {
      float: left;
      display: block;
      font-size: 2.25rem;
      line-height: 3.5rem;
      width: 0;
      position: relative;
      left: 0.5rem;
      color: $midgrey;
    }
    input {
      padding-left: 3rem;
    }
  }
}

.grow {
  flex-grow: 1;
}
.shrink {
  flex-basis: auto;
}

.label {
  font-size: $fontNorm;
  font-weight: 400;
  color: black;
  line-height: 1.5rem;
  display: block;
  height: $labelHeight;
  margin-bottom: 0.25rem;
  &.disabled {
    opacity: 0.4;
  }
}
.hasSublabel {
  flex-basis: auto;
}
.subLabel {
  display: flex;
  justify-content: space-between;
}
.selectWrapper {
  position: relative;
  border-radius: $radiusNorm;
  &:after {
    // content: '';
    // display: block;
    // position: absolute;
    // width: 1px;
    // top: 0;
    // right: 3rem;
    // border-left: $border;
    // height: 100%;
    content: 'arrow_down';
    font-family: 'Osstell Icons';
    position: absolute;
    font-size: $fontMidi;

    /* width: 1px; */
    top: 0;
    right: 0rem;
    // border-left: 1px solid #ededed;
    border-radius: 0 $radiusNorm $radiusNorm 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    pointer-events: none;
    // background-color: #fff;
  }
  &.onWhite:after {
    background-color: $background;
  }
}
.select {
  position: relative;
  background: #fff
    url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4.95 10'> <polygon fill='#000000' points='3.54 5.33 2.48 6.82 1.41 5.33 3.54 5.33'/></svg>")
    no-repeat calc(100% - 10px) -4px;
  background-color: white;
  padding: $padding 2.5rem $padding $padding;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0;
  cursor: pointer;
  border: $border;
  &:hover {
    // border: $hoverborder;
  }
}
.hiddenOption {
  display: none;
}
.timeSelect {
  // display:flex;
  width: 230px;

  .inputItem {
    float: left;
    width: 114px;
    min-width: initial;
    margin-right: 1px;
    padding-right: $padding;
    background: #fff;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.datePicker {
  &:after {
    content: 'calendar';
    font-family: 'Osstell Icons';
    position: absolute;
    font-size: 1.5rem;
    top: 0;
    right: 0;
    border-radius: 0 $radiusNorm $radiusNorm 0;
    height: 100%;
    // width: 3rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1rem;
    pointer-events: none;
    // background-color: #fff;
    color: $greyText;
  }
  &.invalidDate:after {
    color: $ISQlow;
  }

  &.onWhite:after {
    background-color: $background;
  }
  .dateInput {
    padding-right: 3rem;
    cursor: pointer;
  }
}
.datePickerToday {
  &:before {
    border-bottom: 7px solid $purple !important;
  }
}
.datePickerSelectedDay {
  background-color: $purple !important;
  border-radius: $radiusNorm;
}
.dateSelect {
  display: flex;
  // width: 300px;

  .inputItem {
    // float: left;
    // width: 32%;
    min-width: initial;
    margin-right: 1px;
    padding-right: $padding;
    background: #fff;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}
.rangeSelect {
  // display:flex;
  width: 130px;

  .inputItem {
    float: left;
    width: 64px;
    min-width: initial;
    margin-right: 1px;
    padding-right: $padding;
    background: #fff;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}
.tagInput {
  display: flex;
  .tag {
    background-color: #fff;
    padding: $padding;
    margin-right: 1px;
    // float: left;
    // font-size:$fontSmall;
  }
  .add {
    background-color: $lightgrey;
    &:hover {
      background-color: #fff;
    }
  }
}
.image {
  position: relative;
  cursor: pointer;
  .hoverBox {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($vividpurple, 0.8);
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    .description {
      // display: flex;
      max-width: 100px;
      flex-grow: 1;
      // text-align: center;
      color: #fff;
    }
  }
  &:hover {
    .hoverBox {
      display: flex;
    }
  }
  img {
    width: 100%;
  }
}

.sliderWrapper {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.sliderWrapper input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $vividpurple;
}

input:focus + .slider {
  box-shadow: 0 0 1px $vividpurple;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

input {
  background-color: white;
}
.onWhite {
  background-color: $background;
}
