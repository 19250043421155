@import '../../shared/styles/shared';

.dataRow {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.content {
  margin-top: 20px;
  display: flex;
  padding: 1rem 3rem;

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    min-width: 20rem;

    &:not(:first-child) {
      border-left: 1px solid $background;
      padding-left: 4rem;
    }

    &:not(:last-child) {
      padding-right: 4rem;
    }
  }
}

.buttons {
  margin-top: 30px;
  width: calc(100% - 3rem);
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 950px) {
  .content {
    padding: 0px 0px;
    .column {
      &:not(:first-child) {
        padding-left: 10px;
      }
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: 750px) {
  .content {
    flex-direction: column;
    padding: 0px;
    height: 100%;

    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      min-width: unset;
      &:not(:first-child) {
        border-left: unset;
        padding-left: 0;
      }
      &:not(:last-child) {
        padding-right: 0;
      }
    }
  }
  .buttons {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
}

@media (max-width: 450px) {
  .buttons {
    flex-direction: column;
  }
  button:first-child {
    margin: 0px 0px 10px 0px;
  }
}
