.header {
  background-image: url('../../shared/images/about_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  background-position-y: top;
  height: 240px;
  color: #ffffff;
  .headerText {
    width: 500px;
    font-size: 32px;
    font-weight: 600;
    padding: 80px;
  }
}

.content {
  > * {
    display: flex;
  }
}

.contentBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 10px;
  flex-basis: 50%;
}

.isqLogo {
  width: 150px;
  height: 150px;
  margin-right: 50px;
}

.advisors {
  width: 100%;
}

.contact {
  display: block;
  margin-top: 40px;
}

.contactIcons {
  img {
    width: 32px;
    margin: 5px;
    cursor: pointer;
  }
}

.versions {
  display: block;
  font-weight: 200;
}

@media (max-width: 700px) {
  .content {
    display: flex;
    flex-direction: column;
  }

  .missionAndMadeInSwedenContainer {
    display: flex;
    flex-direction: column !important;
  }

  .header {
    display: none;
  }

  .contentBox {
    margin: 20px 0px 0px 0px;
    h2 {
      text-align: center;
    }
    .contentParagraph {
      overflow-wrap: break-word;
    }
  }

  .isqLogo {
    display: none;
  }

  .aboutPage {
    div {
      div {
        span {
          display: none !important;
        }
      }
    }
  }
}
