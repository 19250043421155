@import '../../../shared/styles/shared';

.modal {
  position: fixed;
  top: 0;
  z-index: 100000;
  visibility: hidden;
  height: 100%;
  width: 100%;
  .backdrop {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: hsl(0 0% 100% / 0.8);
    opacity: 1;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    z-index: -1;
  }
  .PanelWrapper {
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0;
  }
  .panel {
    max-height: 95%;
    max-width: 95%;
    overflow-y: auto;
    transition: all 0.35s ease-out;
    flex-basis: initial;
    border: 1px solid #e5e7eb;
    border-radius: $radiusNorm;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
  }

  .h2 {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.125rem;
  }
  .dismissAction {
    float: right;
    font-size: 2rem;
    margin-top: -0.5rem;
    margin-right: 0.5rem;
    color: $vividpurple;
    cursor: pointer;
    &:hover {
      color: $vividpurple;
    }
  }
  .content {
    clear: both;
    padding: 1rem;
  }
}

.show {
  visibility: visible;
  opacity: 1;
  transition: all 0.35s ease-out;
  // .panel {
  //   transform: scale(1);
  // }
}
.hide {
  opacity: 0;
  transition: all 0.35s ease-out;
  // .panel {
  //   transform: scale(0.8);
  // }
}

.fullModal {
  left: 0;
  z-index: 10000001;
}

@media (max-width: 750px) {
  .modal {
    .h2 {
      margin-top: 35px;
    }
    .content {
      padding: 0px 0px !important;
    }
    .panel {
      border-radius: 0px;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      padding: 10px;
      margin: 0px !important;
    }
  }
}
