@import '../../../shared/styles/shared';

.dataItem {
  flex-basis: 25%;

  &.small {
    flex-basis: 12.5%;
  }
  &.span {
    flex-basis: 100%;
  }
  .value {
    font-size: $fontNorm;
  }
  .label {
    font-size: $fontSmall;
    color: black;
    margin-bottom: 0.25rem;
    font-weight: 400;
    padding-right: 3px;
  }
  &.bigVal {
    .value {
      font-size: $fontMega;
      font-weight: 300;
      margin-top: -0.75rem;
    }
  }
  &.midVal {
    .value {
      font-size: $fontBig;
      font-weight: 300;
      margin-top: -0.25rem;
    }
  }
  &.deviceSmall {
    flex-basis: 33.333%;
  }
  &.deviceLarge {
    flex-basis: 66.6667%;
  }
  .trailingStats {
    font-size: $fontSmall;
    display: inline-block;
    margin-left: 0.5rem;
    color: $grey;
    font-weight: 400;
  }
  .insight {
    font-size: $fontMini;
    padding: 0.25rem 1rem;
    background: $vividpurple;
    color: #fff;
    border-radius: 1rem;
    display: inline-block;
    position: relative;
    top: -1.2rem;
  }
  .insightStatus > span {
    display: block;
  }
}

.subDate {
  font-size: 0.8rem;
  font-weight: 300;
  color: #bbb;
  margin-left: 0.5rem;
}
