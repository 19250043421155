@import '../../shared/styles/shared';
.tooth {
  position: relative;
  // width:6.25%;
  flex-basis: calc(7.143% + 1rem);
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  background-color: #fff;
  //background-color: rgb(238, 0, 0);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0);
  transition: all 0.3s ease;
  padding: 10px;
  cursor: pointer;
  .toothModel {
    position: relative;
    i {
      font-family: 'Osstell Icons';
      font-weight: 200;
      font-style: normal;
      font-size: 4.5rem;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      width: 100%;
      visibility: hidden;
      transition: all 0.2s ease;
      &.main {
        display: block;
        // width:0px;
        visibility: visible;
        position: relative;
        color: #fff;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        &.low {
          color: $ISQlow;
        }
        &.mid {
          color: $ISQmedium;
        }
        &.high {
          color: $ISQhigh;
        }
        &.noMeasurement {
          color: $midgrey;
        }
      }
      &.show {
        visibility: visible;
      }
    }
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
  }

  .positionText {
    text-align: center;
    font-size: 0.7rem;
    z-index: 2;
    position: relative;
    position: absolute;
    width: 100%;
    color: $purple;
  }
  .smallText {
    font-size: 0.4rem;
  }
  .isqData {
    text-align: center;
    position: absolute;
    width: 100%;
    font-size: 14px;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    z-index: 1;
    // transform:scale(1.25);
  }
  &.active {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
  &.selected {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: $purple;
    z-index: 1;
    // transform:scale(1.25);
  }

  &.upper {
    margin-bottom: 0.25rem;
    padding-top: 1.5rem;
    text-align: center;
    .positionText {
      top: 0.6rem;
      left: 0rem;
      white-space: nowrap;
    }
  }
  &.upperSmall {
    margin: 0rem;
    padding-top: 1rem;
    text-align: center;
    .positionText {
      top: 0.6rem;
      left: 0rem;
      white-space: nowrap;
    }
  }
  &.lower {
    margin-top: 0.25rem;
    padding-bottom: 1.5rem;
    text-align: center;
    i {
      transform: scaleY(-1);
    }
    .positionText {
      left: 0rem;
      white-space: nowrap;
    }
    .isqData {
      top: 0;
    }
  }
  &.lowerSmall {
    margin: 0rem;
    padding-bottom: 1rem;
    text-align: center;
    i {
      transform: scaleY(-1);
    }
    .positionText {
      left: 0rem;
      white-space: nowrap;
    }
    .isqData {
      top: 0;
    }
  }
}

.tooth a {
  color: transparent;
}

.toothSVG svg {
  height: 90px;
  width: 30px;
}

.toothSVGSmall svg {
  height: 40px;
}

.isqLow path {
  fill: $ISQlow !important;
}

.isqMedium path {
  fill: $ISQmedium !important;
}

.isqHigh path {
  fill: $ISQhigh !important;
}

.implantFlipped {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.implantSVG svg {
  height: 60px;
  width: 20px;
  margin-top: 30px;
}

.implantSVGSmall svg {
  height: 30px;
  margin-top: 10px;
}

.fade {
  opacity: 0.5;
}
.empty {
  min-height: 90px;
  min-width: 20px;
}
.historyIcon {
  font-weight: 600;
  position: absolute;
  bottom: -98px;
  right: 4px;
  transform: scale(1) !important;
  font-size: 1rem;
  .lower & {
    bottom: 93px;
  }
}

@media (max-width: 750px) {
  .empty {
    min-height: 50px;
    min-width: 20px;
  }
}

@media (max-width: 700px) {
  .tooth {
    width: 100%;
  }
}
