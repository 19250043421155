@import '../../../shared/styles/shared';

.spinnerBackground {
  background-color: $vividpurple !important;
}

.headerRow {
  margin-bottom: 1.5rem;
}

.marginTop {
  margin-top: 1rem;
}
