@import '../../shared/styles/shared';
.modalPanel {
  overflow-y: unset !important;
}
.dataRow {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.content {
  display: flex;
  padding: 1rem 3rem;

  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    
    &:not(:first-child) {
      border-left: 1px solid $background;
      padding-left: 4rem;
    }

    &:not(:last-child) {
      padding-right: 4rem;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  justify-content: space-around;
}

@media (max-width: 950px) {
  section {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      margin: 0px;
      max-width: 400px;
    }
    button:first-child {
      margin-bottom: 5px;
    }
  }
}
