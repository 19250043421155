@import '../../shared/styles/shared';

.content {
  max-width: 550px;
}

.tiles {
  display: flex;
  justify-content: space-between;
}

.divider {
  border-bottom: 0.5px solid $tintedLightgrey;
  margin: 40px -160px;
}
.promotionBox {
  background-color: $purple;
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: $radiusNorm;
  box-shadow: $toggledShadow;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  p {
    color: #fff;
    margin-right: 0.5rem;
  }
}

.newsContent {
  .header {
    color: $purple;
    font-size: 16px;
  }
  .logoCommunity {
    svg {
      width: 200px;
      display: block;
      margin: 0 auto;
    }
  }
}

.promotionsContainer {
  display: flex;
  align-items: flex-start;
  h4 {
    margin-left: 5px;
  }
}

.campaignVideo {
  video {
    border-radius: 5px;
  }
  margin-bottom: 10px;
}

.textLink {
  cursor: pointer;
  color: #a62193;
  &:hover {
    text-decoration: underline;
  }
}

.linkContainer {
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: $radiusNorm;
  box-shadow: $toggledShadow;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  min-height: 150px;
  background-color: #fff;

  .imgHolder {
    width: 100%;
    img {
      height: 7rem;
      width: auto;
    }
  }

  .contentHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 60%;
    p {
      text-align: center;
    }
    .header {
      color: $purple;
      font-size: 16px;
    }
  }
}

@media (max-width: 750px) {
  .promotionBox {
    p {
      text-align: center;
    }
  }
  .promotionsContainer {
    flex-direction: column;
    margin-bottom: 1rem;
    h2 {
      text-align: center;
      margin: 0;
    }
    h3 {
      color: black !important;
      font-weight: 300;
      text-align: center;
    }
    h4 {
      margin: 0;
    }
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .tiles {
    display: flex;
    flex-direction: column;
  }

  .promotionBox {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  .divider {
    margin: 17px;
  }

  .newsContent {
    .header {
      margin-top: 0px;
    }
  }

  .linkContainer {
    img {
      display: none;
    }
    .contentHolder {
      min-width: 100%;
    }
  }
}
