@import '../../../../shared/styles/shared';

.video {
  video {
    border: 1px solid;
    border-radius: $radiusNorm;
  }
}

.videoWrapper {
  max-width: 1000px;
}

.fixedSize {
  width: 640px;
  height: 360px;
}
