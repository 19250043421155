@import '../../../../shared/styles/shared';

.buttons {
  display: flex;
  * {
    margin: 0 15px 0 15px;
  }
}

@media (max-width: 750px) {
  .buttons {
    flex-direction: column;
    button {
      margin: 0px;
      margin-top: 10px;
      margin-bottom: 0px !important;
    }
  }
}
