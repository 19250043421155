.preview, .page {
  font-size: 12pt;
  color: black;
  width: 297mm;
  position: relative;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;
}

.page {
  display: block;
}
