@import '../../shared/styles/shared';
.modal {
  width: 700px;
  height: 700px;
  p {
    font-weight: 300;
    font-size: $fontMidi;
    text-align: center;
    margin: 0.5rem 0;
    svg {
      display: inline-block;
      position: relative;
      top: 3px;
    }
  }
}
.note {
  p {
  }
}

.h2 {
  font-size: 1.5rem;
}
.buttonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.5rem;
}
.stepNav {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-weight: 300;
}
.content {
  display: flex;
  flex-flow: column;
}
.mainWrapper {
  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.modalInner {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding: 0 1rem;
}
.footer {
  widows: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.dataRow {
  display: flex;
  margin-top: 0.5rem;
}
.illustrationWrapper {
  padding: 1rem 1rem;
  border-radius: 8px;
  border: 3px solid #f4f5fc;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  margin: 0.5rem 0rem;
  img,
  video {
    width: 98%;
  }
}
.narrow {
  width: 340px;
}
.productImage {
  //display:flex;
  img {
    max-width: 100%;
    margin: 0.5rem auto;
    display: block;
  }
  text-align: center;
  cursor: pointer;
}
.productImageSelected {
  border: 4px solid $vividpurple;
}
p.puk {
  font-size: 6rem;
  text-align: center;
  margin: 0;
}
.pukWrapper {
  text-align: center;
  opacity: 0;
}
.errorBox {
  position: relative;
  p {
    color: $warning;
  }
}
.showPuk {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.freeSmartPegCheckBox {
  margin: 0;
  text-align: left;
}

.freeSmartPegCheckBox input {
  margin-top: 6.2px !important;
  cursor: pointer;
}

.freeSmartPegCheckBox label {
  font-size: 16px;
}

@media (max-width: 550px) {
  .buttonWrapper {
    flex-direction: column;
    button {
      margin-bottom: 10px !important;
      width: 100% !important;
      height: 50px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        img {
          display: none;
        }
      }
    }
  }

  .narrow {
    width: 260px;
  }

  .footer {
    flex-direction: column;
  }

  .dowloadFirmware {
    div:first-child {
      display: none;
    }
  }
}
