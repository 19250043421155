@import '../../../shared/styles/shared';
.alertBox {
  animation: pulse 1.5s infinite alternate;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  h2 {
    color: #fff;
    background-color: $warning;
    font-weight: 300;
    padding: 0.5rem;
    margin-bottom: 0;
    font-size: $fontSmall;
  }

  @keyframes pulse {
    from {
      box-shadow: 0px 0px 10px rgba($warning, 0.2);
    }
    to {
      box-shadow: 0px 0px 40px rgba($warning, 1);
    }
  }

  ul {
    padding: 0.5rem;
    margin: 0;
    list-style: none;
  }
}
