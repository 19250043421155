@import '../../shared/styles/shared';

.tile {
  border-radius: 10px;
  cursor: pointer;
  width: 250px;
  height: 185px;
  box-sizing: border-box;
  margin: 0.5rem 0.499rem;
  transition: all 0.3s ease;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

  .thumbnail {
    z-index: -1;
    height: 120px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    &:before {
      content: '';
      display: block;
      height: 120px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $darkpurple;
      opacity: 0.5;
      border-radius: 10px 10px 0 0;
    }
  }
}
.tile:hover {
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.08);
}
.icon {
  text-align: center;
  font-size: 4rem;
  color: #fff;
  line-height: 120px;
  position: relative;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: $purple;
    padding: 0.5rem;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .tile {
    width: 90%;
    height: 94px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .thumbnail {
    background-image: none !important;
    height: 40px !important;
    &:before {
      display: block;
      height: 40px !important;
    }
  }

  .icon {
    line-height: 47px !important;
    font-size: 2rem;
  }

  .title {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
