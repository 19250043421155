@import '../../../shared/styles/shared';

.actions {
  border-top: 1px gray solid;
}

.flexStatusContainer {
  flex-grow: 10;
}

.spinnerBackground {
  background-color: $vividpurple !important;
}

.headerRow {
  margin-bottom: 1.5rem;
}

.marginTop {
  margin-top: 1rem;
}

.marginRight {
  margin-right: 5px;
}

.subDate {
  font-size: $fontSmall;
  font-weight: 300;
  color: $grey;
  margin-left: 0.5rem;
}
.actionBox {
  margin-left: -1rem;
  margin-right: -1rem;
  // margin-bottom: -1rem;
  padding: 0.5rem 1rem 0;
  // border-top:1px solid $subtlegrey;
  background: $subtlegrey;
  a {
    font-size: $fontSmall;
  }
  .linkAction {
    line-height: 3.5rem;
  }
  .warning {
    color: $warning;
  }
  .roleWrapper {
    width: 13rem;
  }
  .actions {
    //margin-top: -2rem;
    > * {
      display: flex;
    }
  }
}

.instrumentStatus {
  //margin-top: 20px;
}

.fullWidth {
  max-width: 100% !important;
}

.findButton {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.searchTypeDropdown {
  width: 250px;
}

.instrumentImage {
  width: 70px;
}

.blocked {
  color: red;
}
