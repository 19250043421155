@import '../../shared/styles/shared';

.report {
  position: relative;
  align-items: center;
  flex-direction: column;
  max-width: fit-content;
  margin: auto;
}

.reportContent {
  overflow-y: scroll;
  width: 210.312mm;
}

.header {
  font-weight: 200;
  margin-top: 10mm;
  margin-bottom: 0mm;
  font-size: 40pt;
  color: var(--color-purple);
  justify-content: center;
  text-align: center;
}

.subHeader {
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 10mm;
  font-size: 12pt;
  color: var(--color-purple);
  justify-content: center;
  text-align: center;
}

.page {
  font-size: 12pt;
  color: black;
  width: 210.312mm;
  position: relative;
  box-sizing: border-box;
  background-color: white;

  .aspectRatioBoxContentBox {
    padding: 20mm;
  }
}

.edit {
  .page {
    border: 1px solid black;
  }
}

.timeline {
  background: #edeef7;
  padding: 2rem;
  box-sizing: border-box;
  overflow: hidden;

  > * {
    transform-origin: top left;
    transform: scale(0.715);
  }
}

.timeline * {
  flex-grow: 0;
  flex-shrink: 0;
}

.timeline {
  border: solid red 3px;
  width: width;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
}

.visitPage {
  height: 210mm;
  width: 297mm;
}

.myStepWrapper {
  height: 96mm;
  display: flex;
  .descriptionFlexChild {
    box-sizing: border-box;
    width: 50%;
    padding-top: 9px;
    padding-left: 20px;
    font-family: $fontFamily;
  }

  .imageFlexChildFirst {
    padding-top: 25px;
  }

  .imageFlexChild {
    width: 50%;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
}

.visitPageHeader {
  display: flex;
  background-color: var(--color-purple);
  height: 16mm;
  place-items: center;

  > span {
    text-transform: uppercase;
    margin-left: 20mm;
    min-width: 75mm;
    box-sizing: border-box;
    height: 11mm;
    background-color: rgba(255, 255, 255, 0.3);
    color: white;
    border-radius: 8px;
    border: none;
    font-size: 12pt;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0.5mm 2mm;
  }
}

.stepWrapper {
  padding: 8mm 0;
}

.stepBody {
  padding-top: 0.5rem;
  display: flex;
  font-weight: 200;

  > div {
    flex: 1;
  }

  .stepDescription {
    max-width: 250px;
    overflow-wrap: break-word;
  }

  img {
    width: 380px;
  }
}

.footer {
  position: absolute;
  display: block;
  bottom: 5mm;
  left: calc(50% - 80px);

  img {
    width: 120px;
  }

  p {
    font-weight: 200;
    font-size: 12pt;
    color: var(--color-purple);
    text-align: center;
    margin: 0;
  }
}

.logoDiv {
  box-sizing: border-box;
  padding: 5mm 5mm 0mm 0mm;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 5px;
    .topRightCornerInput {
      div {
        label {
          justify-content: center;
        }
      }
    }
    img {
      max-height: 70px;
      width: auto;
      max-width: 1000px;
    }
    .logoAdress {
      margin-top: 5px;
      display: flex;
      font-size: 11pt;
    }
  }
}
